import React from "react";
import {Route, Switch} from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ProtectedRoute from './components/global/ProtectedRoute';

//import Dashboard from "./components/Dashboard";
import DashboardAlt from "./components/DashboardAlt";

import BatchOperations from "./components/admin/batchOperations";

//import VerificationScheduleDashExpanded from "./components/statistics/verificationScheduleStatsDashExp";
import VerificationScheduleStatsDashExpAlt from "./components/statistics/verificationScheduleStatsDashExpAlt";

import ViewUserProfile from "./components/users/viewUserProfile";
import UserHistoryDiff from "./components/users/userHistoryDiff";
import UserSignature from "./components/users/signature";
import AddEditUser from "./components/users/addEditUser";
import AddEditLocation from "./components/locations/addEditLocation";
import ViewLocation from "./components/locations/ViewLocation";
import Locations from "./components/locations/Locations";
import LocationHistory from "./components/locations/locationHistory";
import LocationHistoryDiff from "./components/locations/locationHistoryDiff";
import UserHistories from "./components/users/userHistories";
import UserAccessControl from "./components/users/accessControl";
import Users from "./components/users/users";
import ViewCompetencyCat from "./components/competency/viewCompetencyCat";
import AddEditCompetencyCat from "./components/competency/addEditCompetencyCat";
import CompCategoryHistory from "./components/competency/compCategoryHistory";
import CompCategoryHistoryDiff from "./components/competency/compCategoryHistoryDiff";
import CompetencyCats from "./components/competency/competencyCats";
import ViewCompetency from "./components/competency/viewCompetency";
import AddEditCompetency from "./components/competency/addEditCompetency";
import CompetencyHistory from "./components/competency/competencyHistory";
import CompetencyHistoryDiff from "./components/competency/competencyHistoryDiff";
import CompetencyManager from "./components/competency/competencyManager";
import UserCompetencyHistory from "./components/competency/userCompetencyHistory";
import UserCompetencyHistoryDiff from "./components/competency/userCompetencyHistoryDiff";
import ViewUserCompetency from "./components/competency/viewUserCompetency";
import AddEditUserCompetency from "./components/competency/addEditUserCompetency";
import UserCompetency from "./components/competency/userCompetency";
import AddDutyHolder from "./components/dutyHolders/addDutyHolder";
import EditDutyHolder from "./components/dutyHolders/editDutyHolder";
import ViewDutyHolder from "./components/dutyHolders/viewDutyHolder";
import DutyHolderHistoryDiff from "./components/dutyHolders/dutyHolderHistoryDiff";
import DutyHolderHistories from "./components/dutyHolders/dutyHolderHistories";
import AssetGroupHistories from "./components/assetGroups/assetGroupHistories";
import AssetGroupHistoryDiff from "./components/assetGroups/assetGroupHistoryDiff";
import ViewVerificationActivity from "./components/verificationActivities/viewVerificationActivity";
import AddEditVerificationActivity from "./components/verificationActivities/addEditVerificationActivity";
import VerificationActivities from "./components/verificationActivities/verificationActivities";
import VerificationActivityHistories from "./components/verificationActivities/verificationActivityHistories";
import VerificationActivityHistoryDiff from "./components/verificationActivities/verificationActivityHistoryDiff";
import ViewReferenceActivity from "./components/referenceActivities/viewReferenceActivity";
import AddEditReferenceActivity from "./components/referenceActivities/addEditReferenceActivity";
import ReferenceActivities from "./components/referenceActivities/ReferenceActivities";
import ReferenceActivityHistory from "./components/referenceActivities/referenceActivityHistory";
import ReferenceActivityHistoryDiff from "./components/referenceActivities/referenceActivityHistoryDiff";
import ViewPerformanceStandard from "./components/performanceStandards/viewPerformanceStandard";
import AddEditPerformanceStandards from "./components/performanceStandards/addEditPerformanceStandards";
import PerformanceStandards from "./components/performanceStandards/PerformaceStandards";
import PerformanceStandardHistory from "./components/performanceStandards/performanceStandardHistory";
import PerformanceStandardHistoryDiff from "./components/performanceStandards/performanceStandardHistoryDiff";
import ViewMAH from "./components/majorAccidentHazards/viewMajorAccidentHazard";
import AddEditMAH from "./components/majorAccidentHazards/addEditMajorAccidentHazard";
import MajorAccidentHazards from "./components/majorAccidentHazards/majorAccidentHazards";
import MajorAccidentHazardHistories from "./components/majorAccidentHazards/majorAccidentHazardHistories";
import MajorAccidentHazardHistoryDiff from "./components/majorAccidentHazards/majorAccidentHazardHistoryDiff";
import ViewSece from "./components/seces/viewSece";
import AddEditSece from "./components/seces/addEditSece";
import Seces from "./components/seces/seces";
import ViewBarrier from "./components/barriers/viewBarrier";
import AddEditBarrier from "./components/barriers/addEditBarrier";
import Barriers from "./components/barriers/Barriers";
import ViewInstallation from "./components/installations/viewInstallation";
import AddEditInstallation from "./components/installations/addEditInstallation";
import InstallationHistories from "./components/installations/installationHistories";
import InstallationHistoryDiff from "./components/installations/installationHistoryDiff";
import Installations from "./components/installations/Installations";
import ViewAssetGroup from "./components/assetGroups/viewAssetGroup";
import AddEditAssetGroup from "./components/assetGroups/addEditAssetGroup";
import AssetGroups from "./components/assetGroups/assetGroups";
import BarrierHistories from "./components/barriers/barrierHistories";
import BarrierHistoryDiff from "./components/barriers/barrierHistoryDiff";
import SeceHistories from "./components/seces/seceHistories";
import SeceHistoryDiff from "./components/seces/seceHistoryDiff";
import DutyHolders from "./components/dutyHolders/dutyHolders";
import VerificationRegister from "./components/verification/verificationRegister";
import VerificationIndex from "./components/verification/VerificationIndex";
import FindingManagement from "./components/findings/FindingManagement";

//import FindingEditorFilter from "./components/findings/FindingEditorFilter";
import Findings from "./components/findings/findings";
import ViewFinding from "./components/findings/ViewFinding";
import ViewActivity from "./components/activitySearch/viewActivity";
// import ActivitySearch from "./components/activitySearch/activitySearch";
import ActivitySearchAlt from "./components/activitySearch/activitySearchAlt";
import ActivitySummary from "./components/activitySearch/activitySummary";
import ActionList from "./components/reporting/actionList";
import Reporting from "./components/reporting/reporting";
import ViewReport from "./components/reporting/viewReport";
import addEditReport from "./components/reporting/addEditReport";
import ViewLinkedActivity from "./components/reporting/viewLinkedActivity";
import ViewAssetVerification from "./components/reporting/viewAssetVerification";
import ViewPeriodicVerification from "./components/reporting/viewPeriodicVerification";
import verificationScheduleReportIndex from "./components/reporting/verificationScheduleReportIndex";
import addEditVerificationScheduleReport from "./components/reporting/addEditVerificationScheduleReport";
import viewVerificationScheduleReport from "./components/reporting/viewVerificationScheduleReport";
import verificationScheduleReports from "./components/reporting/verificationScheduleReports";
import Documentation from "./components/documentation/Documentation";
import FileManagement from "./components/documentation/FileManagement";
import SeceStatus from "./components/seces/seceStatus";

//import Statistics from "./components/statistics/statistics";
import StatisticsAlt from "./components/statistics/statisticsAlt";
import ComparisonEngine from "./components/comparisonEngine/comparisonEngine";
//import BowtieDash from "./components/Bowties/BowtieDash";
import BowtieDashAlt from "./components/Bowties/bowtieDashAlt";
//import Bowtie from "./components/Bowties/bowtie";
import BowtieAlt from "./components/Bowties/bowtieAlt";
import AddEditInfoMatrix from "./components/infoMatrix/addEditInfoMatrix";
import InfoMatrix from "./components/infoMatrix/infoMatrix";
import AccessLog from "./components/accessLog/accessLog";
import Notifications from "./components/notifications-alerts/notifications";
import NotificationUpdateDiff from "./components/notifications-alerts/notificationUpdateDiff";
import AddEditNotice from "./components/notifications-alerts/addEditNotice";
import ViewNotice from "./components/notifications-alerts/viewNotice";
import Notices from "./components/notifications-alerts/notices";
import SystemSettings from "./components/systemSettings/systemSettings";
import SystemVariables from "./components/systemSettings/systemVariables";
import AddEditSystemVariables from "./components/systemSettings/addEditSystemVariables";
import AccessPermissions from "./accessPermissions";
import RouteNotFound from "./404";

const {REACT_APP_TOGGLE_FEATURE_USER = undefined} = process.env;

console.log(REACT_APP_TOGGLE_FEATURE_USER);
const Routes = React.memo(() => {

  return (
    <Switch>
      {/* <Route path="/" exact component={Dashboard}/> */}
      <Route path="/" exact component={DashboardAlt}/>
      <Route exact path="/access-permissions" component={AccessPermissions}/>
      <Route path="/user/view/:userId" component={ViewUserProfile}/>
      <Route path="/dutyHolder/:dhId/user/view/:userId" component={ViewUserProfile}/>

      <Route path="/user/view-diff/:id/:parentId" component={UserHistoryDiff}/>
      <Route path="/dutyHolder/:dhId/user/view-diff/:id/:parentId" component={UserHistoryDiff}/>

      <Route path="/users/signature/:userId" exact component={UserSignature}/>
      <Route path="/dutyHolder/:dhId/users/signature/:userId" exact component={UserSignature}/>

      <Route path="/user/:formMode/:userId" component={AddEditUser}/>
      <Route path="/user/:formMode" component={AddEditUser}/>

      <Route path="/dutyHolder/:dhId/user/:formMode/:userId" component={AddEditUser}/>
      <Route path="/dutyHolder/:dhId/user/:formMode" component={AddEditUser}/>

      <Route path="/locations/:formMode/:locationId" component={AddEditLocation}/>
      <Route path="/locations/:formMode" component={AddEditLocation}/>
      <Route path="/locations/view" component={ViewLocation}/>
      <Route path="/locations" component={Locations}/>

      <Route path="/:featureName/:featureId/locations/view/:locationId" component={ViewLocation}/>
      <Route path="/:featureName/:featureId/locations/histories" component={LocationHistory}/>
      <Route path="/:featureName/:featureId/location/:locationId/histories" component={LocationHistory}/>
      <Route path="/:featureName/:featureId/locations/history-diff/:id/:parentId" component={LocationHistoryDiff}/>
      <Route path="/:featureName/:featureId/locations/:formMode/:locationId" component={AddEditLocation}/>
      <Route path="/:featureName/:featureId/locations/:formMode" component={AddEditLocation}/>
      <Route path="/:featureName/:featureId/locations" component={Locations}/>

        {REACT_APP_TOGGLE_FEATURE_USER !== 'off' && (<Route path="/users/histories" component={UserHistories}/>)}
        {REACT_APP_TOGGLE_FEATURE_USER !== 'off' && (<Route path="/dutyHolder/:dhId/users/histories" component={UserHistories}/>)}
        {REACT_APP_TOGGLE_FEATURE_USER !== 'off' && (<Route exact path="/users/access/:userId" component={UserAccessControl}/>)}
        {REACT_APP_TOGGLE_FEATURE_USER !== 'off' && (<Route path="/dutyHolder/:dhId/users/access/:userId" component={UserAccessControl}/>)}
        {/*{REACT_APP_TOGGLE_FEATURE_USER !== 'off' && (<Route path="/users/:archived" component={Users}/>)}*/}
        {REACT_APP_TOGGLE_FEATURE_USER !== 'off' && (<Route path="/users" component={Users}/>)}
        {REACT_APP_TOGGLE_FEATURE_USER !== 'off' && (<Route path="/dutyholder/:dhId/users" component={Users}/>)}

      <Route path="/user-competency/competencies/competency-cat/view/:compCatId" component={ViewCompetencyCat}/>
      <Route path="/user-competency/competencies/competency-cat/:formMode/:compCatId"
             component={AddEditCompetencyCat}/>
      <Route path="/user-competency/competencies/competency-cat/:formMode" component={AddEditCompetencyCat}/>
      <Route exact path="/user-competency/competencies/competency-cats/histories" component={CompCategoryHistory}/>
      <Route exact path="/user-competency/competencies/competency-cats/history-diff/:id/:parentId"
             component={CompCategoryHistoryDiff}/>
      <Route path="/user-competency/competencies/competency-cats" component={CompetencyCats}/>
      <Route path="/user-competency/competency/view/:compId" component={ViewCompetency}/>
      <Route path="/user-competency/competency/:formMode/:compId" component={AddEditCompetency}/>
      <Route path="/user-competency/competency/:formMode" component={AddEditCompetency}/>
      <Route exact path="/user-competency/competencies/histories" component={CompetencyHistory}/>
      <Route exact path="/user-competency/competencies/history-diff/:id/:parentId"
             component={CompetencyHistoryDiff}/>
      <Route path="/user-competency/competencies" component={CompetencyManager}/>

      <Route path="/user-competency/histories" component={UserCompetencyHistory}/>
      <Route path="/user-competency/history-diff/:id/:parentId" component={UserCompetencyHistoryDiff}/>
      <Route path="/user-competency/view/:userCompId" component={ViewUserCompetency}/>
      <Route path="/user-competency/:formMode/:userCompId" component={AddEditUserCompetency}/>
      <Route path="/user-competency/:formMode" component={AddEditUserCompetency}/>
      <Route path="/user-competencies" component={UserCompetency}/>

      <Route path="/dutyholders/histories" component={DutyHolderHistories} />
      <ProtectedRoute path="/dutyholder/:id/histories" component={DutyHolderHistories} />
      <ProtectedRoute path="/dutyholder/view-diff/:hisId/:id" component={DutyHolderHistoryDiff} />
      <ProtectedRoute path="/dutyholders/view/:id" component={ViewDutyHolder} />
      <ProtectedRoute path="/dutyholder/edit/:id" component={EditDutyHolder} />
      
      <Route path="/dutyholder/add" component={AddDutyHolder}/>
      <Route path="/dutyholders" component={DutyHolders}/>

      <ProtectedRoute path="/dutyholder/:id/asset-groups/histories" component={AssetGroupHistories}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/view-diff/:hisId/:parentId" component={AssetGroupHistoryDiff}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/performance-standard/:psId/verification-activity/:vaId/reference-activity/view/:raId" component={ViewReferenceActivity}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/performance-standard/:psId/verification-activity/:vaId/reference-activity/:formMode/:raId" component={AddEditReferenceActivity}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/performance-standard/:psId/verification-activity/:vaId/reference-activity/:formMode" component={AddEditReferenceActivity}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/performance-standard/:psId/verification-activity/:vaId/reference-activities/histories" component={ReferenceActivityHistory}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/performance-standard/:psId/verification-activity/:vaId/reference-activities/view-diff/:hisId/:parentId" component={ReferenceActivityHistoryDiff}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/performance-standard/:psId/verification-activity/:vaId/reference-activities" component={ReferenceActivities}/>

      <ProtectedRoute
        path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/performance-standard/:psId/verification-activity/view/:vaId"
        component={ViewVerificationActivity}/>
      <ProtectedRoute path="/verification-activities/view/:vaId" component={ViewVerificationActivity}/>

      <ProtectedRoute
        path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/performance-standard/:psId/verification-activity/:formMode/:vaId"
        component={AddEditVerificationActivity}/>
      <ProtectedRoute
        path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/performance-standard/:psId/verification-activity/:formMode"
        component={AddEditVerificationActivity}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/performance-standard/:psId/verification-activities/histories"
             component={VerificationActivityHistories}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/performance-standard/:psId/verification-activities/view-diff/:hisId/:parentId"
             component={VerificationActivityHistoryDiff}/>
      <ProtectedRoute
        path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/performance-standard/:psId/verification-activities"
        component={VerificationActivities}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/performance-standard/:psId/reference-activity/view/:raId" component={ViewReferenceActivity}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/performance-standard/:psId/reference-activity/:formMode/:raId" component={AddEditReferenceActivity}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/performance-standard/:psId/reference-activity/:formMode" component={AddEditReferenceActivity}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/performance-standard/:psId/reference-activities/histories" component={ReferenceActivityHistory}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/performance-standard/:psId/reference-activities/view-diff/:hisId/:parentId" component={ReferenceActivityHistoryDiff}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/performance-standard/:psId/reference-activities" component={ReferenceActivities}/>

      <ProtectedRoute
        path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/performance-standard/view/:psId"
        component={ViewPerformanceStandard}/>

      <ProtectedRoute path="/performance-standards/view/:psId" component={ViewPerformanceStandard}/>

      <ProtectedRoute
        path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/performance-standard/:formMode/:psId"
        component={AddEditPerformanceStandards}/>
      <ProtectedRoute
        path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/performance-standard/:formMode"
        component={AddEditPerformanceStandards}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/performance-standards/histories"
             component={PerformanceStandardHistory}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/performance-standards/view-diff/:hisId/:parentId"
             component={PerformanceStandardHistoryDiff}/>
      <ProtectedRoute
        path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/performance-standards"
        component={PerformanceStandards}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/reference-activity/view/:raId" component={ViewReferenceActivity}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/reference-activity/:formMode/:raId" component={AddEditReferenceActivity}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/reference-activity/:formMode" component={AddEditReferenceActivity}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/reference-activities/histories" component={ReferenceActivityHistory}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/reference-activities/view-diff/:hisId/:parentId" component={ReferenceActivityHistoryDiff}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:seceId/reference-activities" component={ReferenceActivities}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/reference-activity/view/:raId" component={ViewReferenceActivity}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/reference-activity/:formMode/:raId" component={AddEditReferenceActivity}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/reference-activity/:formMode" component={AddEditReferenceActivity}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/reference-activities/histories" component={ReferenceActivityHistory}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/reference-activities/view-diff/:hisId/:parentId" component={ReferenceActivityHistoryDiff}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/reference-activities" component={ReferenceActivities}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/seces/histories" component={SeceHistories}/>

      <ProtectedRoute
        path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/view/:seceId"
        component={ViewSece}/>
      <ProtectedRoute path="/seces/view/:seceId" component={ViewSece}/>

      <ProtectedRoute
        path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:formMode/:seceId"
        component={AddEditSece}/>
      <ProtectedRoute
        path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/sece/:formMode"
        component={AddEditSece}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:barrierId/seces"
             component={Seces}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/view/:barrierId"
             component={ViewBarrier}/>
      <ProtectedRoute
        path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:formMode/:barrierId"
        component={AddEditBarrier}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/performance-standard/:psId/verification-activity/:vaId/reference-activity/view/:raId" component={ViewReferenceActivity}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/performance-standard/:psId/verification-activity/:vaId/reference-activity/:formMode/:raId" component={AddEditReferenceActivity}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/performance-standard/:psId/verification-activity/:vaId/reference-activity/:formMode" component={AddEditReferenceActivity}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/performance-standard/:psId/verification-activity/:vaId/reference-activities/histories" component={ReferenceActivityHistory}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/performance-standard/:psId/verification-activity/:vaId/reference-activities/view-diff/:hisId/:parentId" component={ReferenceActivityHistoryDiff}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/performance-standard/:psId/verification-activity/:vaId/reference-activities" component={ReferenceActivities}/>

      <ProtectedRoute
        path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/performance-standard/:psId/verification-activity/view/:vaId"
        component={ViewVerificationActivity}/>
      <ProtectedRoute
        path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/performance-standard/:psId/verification-activity/:formMode/:vaId"
        component={AddEditVerificationActivity}/>
      <ProtectedRoute
        path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/performance-standard/:psId/verification-activity/:formMode"
        component={AddEditVerificationActivity}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/performance-standard/:psId/verification-activities/histories"
             component={VerificationActivityHistories}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/performance-standard/:psId/verification-activities/view-diff/:hisId/:parentId"
             component={VerificationActivityHistoryDiff}/>
      <ProtectedRoute
        path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/performance-standard/:psId/verification-activities"
        component={VerificationActivities}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/performance-standard/:psId/reference-activity/view/:raId" component={ViewReferenceActivity}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/performance-standard/:psId/reference-activity/:formMode/:raId" component={AddEditReferenceActivity}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/performance-standard/:psId/reference-activity/:formMode" component={AddEditReferenceActivity}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/performance-standard/:psId/reference-activities/histories" component={ReferenceActivityHistory}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/performance-standard/:psId/reference-activities/view-diff/:hisId/:parentId" component={ReferenceActivityHistoryDiff}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/performance-standard/:psId/reference-activities" component={ReferenceActivities}/>

      <ProtectedRoute
        path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/performance-standard/view/:psId"
        component={ViewPerformanceStandard}/>
      <ProtectedRoute
        path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/performance-standard/:formMode/:psId"
        component={AddEditPerformanceStandards}/>
      <ProtectedRoute
        path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/performance-standard/:formMode"
        component={AddEditPerformanceStandards}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/performance-standards/histories"
             component={PerformanceStandardHistory}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/performance-standards/view-diff/:hisId/:parentId"
             component={PerformanceStandardHistoryDiff}/>
      <ProtectedRoute
        path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/performance-standards"
        component={PerformanceStandards}/>

       <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/mahs/histories"
             component={MajorAccidentHazardHistories}/>

       <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/mahs/view-diff/:hisId/:parentId" component={MajorAccidentHazardHistoryDiff}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/reference-activity/view/:raId" component={ViewReferenceActivity}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/reference-activity/:formMode/:raId" component={AddEditReferenceActivity}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/reference-activity/:formMode" component={AddEditReferenceActivity}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/reference-activities/histories" component={ReferenceActivityHistory}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/reference-activities/view-diff/:hisId/:parentId" component={ReferenceActivityHistoryDiff}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:seceId/reference-activities" component={ReferenceActivities}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/mah/:mahId/reference-activity/view/:raId" component={ViewReferenceActivity}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/mah/:mahId/reference-activity/:formMode/:raId" component={AddEditReferenceActivity}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/mah/:mahId/reference-activity/:formMode" component={AddEditReferenceActivity}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/mah/:mahId/reference-activities/histories" component={ReferenceActivityHistory}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/mah/:mahId/reference-activities/view-diff/:hisId/:parentId" component={ReferenceActivityHistoryDiff}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/mah/:mahId/reference-activities" component={ReferenceActivities}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/mah/view/:mahId" component={ViewMAH}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/mah/:formMode/:mahId" component={AddEditMAH}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/mah/:formMode" component={AddEditMAH}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/mahs" component={MajorAccidentHazards}/>

      <ProtectedRoute
        path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/reference-activity/view/:raId"
        component={ViewReferenceActivity}/>
      <ProtectedRoute
        path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/reference-activity/:formMode/:raId"
        component={AddEditReferenceActivity}/>
      <ProtectedRoute
        path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/reference-activity/:formMode"
        component={AddEditReferenceActivity}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/reference-activities/histories" component={ReferenceActivityHistory}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/reference-activities/view-diff/:hisId/:parentId" component={ReferenceActivityHistoryDiff}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/reference-activities" component={ReferenceActivities}/>


      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barriers/histories" component={BarrierHistories}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barrier/:formMode" component={AddEditBarrier}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/seces/histories" component={SeceHistories}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/seces/view-diff/:hisId/:parentId" component={SeceHistoryDiff}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/view/:seceId"
             component={ViewSece}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:formMode/:seceId"
             component={AddEditSece}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/sece/:formMode"
             component={AddEditSece}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/seces" component={Seces}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/barriers" component={Barriers}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/histories" component={InstallationHistories}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/histories" component={InstallationHistories}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:installationId/view"
             component={ViewInstallation}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:formMode/:installationId"
             component={AddEditInstallation}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installation/:formMode"
             component={AddEditInstallation}/>

      <ProtectedRoute path="/dutyholder/:id/installation/view-diff/:hisId/:parentId" component={InstallationHistoryDiff}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/reference-activity/view/:raId" component={ViewReferenceActivity}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/reference-activity/:formMode/:raId" component={AddEditReferenceActivity}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/reference-activity/:formMode" component={AddEditReferenceActivity}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/reference-activities/histories" component={ReferenceActivityHistory}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/reference-activities/view-diff/:hisId/:parentId" component={ReferenceActivityHistoryDiff}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/reference-activities" component={ReferenceActivities}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/:assetGroupId/installations" component={Installations}/>

      <ProtectedRoute path="/dutyholder/:id/asset-group/view/:assetGroupId" component={ViewAssetGroup}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:formMode/:assetGroupId" component={AddEditAssetGroup}/>
      <ProtectedRoute path="/dutyholder/:id/asset-group/:formMode" component={AddEditAssetGroup}/>

      <ProtectedRoute path="/dutyholder/:id/asset-groups" component={AssetGroups}/>

      <ProtectedRoute path="/dutyholder/:id/barriers/view-diff/:hisId/:parentId" component={BarrierHistoryDiff}/>

      <Route path="/verification/verification-register" component={VerificationRegister}/>
      <Route path="/verification" component={VerificationIndex}/>

      <Route path="/reporting/finding/view/:findingId" component={ViewFinding}/>
      <Route path="/reporting/:rptId/finding/:formMode/:findingId" component={FindingManagement} key={uuidv4()}/>
      <Route path="/reporting/:rptId/finding/:formMode" component={FindingManagement} key={uuidv4()}/>
      <Route path="/reporting/vs/:vsId/finding/:formMode" component={FindingManagement} key={uuidv4()}/>
      <Route path="/reporting/finding/:formMode/:findingId" component={FindingManagement} key={uuidv4()}/>
      
      {/* <Route exact path="/reporting/finding/filter-info" component={FindingEditorFilter} key={uuidv4()}/>*/}
      <Route path="/reporting/finding/:formMode" component={FindingManagement} key={uuidv4()}/>
      
     
      <Route path="/reporting/findings" component={Findings}/>
      <Route path="/reporting/:reportId/findings" component={Findings}/>

      {/* <Route path="/reporting/activity-search/view/:vsId/:vaId" component={ViewActivity}/> */}
      <Route exact path="/reporting/activity-search/view/:vaId" component={ViewActivity}/>
      <Route path="/reporting/activity-summary" component={ActivitySummary}/>
      {/* <Route exact path="/reporting/activity-search" component={ActivitySearch}/> */}
      <Route exact path="/reporting/activity-search" component={ActivitySearchAlt}/>

      <Route path="/reporting/my-action-list" component={ActionList} />
      <Route path="/reporting/action-list" component={ActionList} />

      {/*<Route path="/reporting/report-search" component={Reporting}/>*/}
      <Route path="/reporting/vs-report/view/:vsRptId" component={viewVerificationScheduleReport}/>
      <Route path="/reporting/report-search" component={verificationScheduleReports}/>
      <Route path="/reporting/vs-report/report-index/:formMode/:vsRptId" component={verificationScheduleReportIndex}/>
      <Route path="/reporting/vs-report/report-index/:formMode" component={verificationScheduleReportIndex}/>
      <Route path="/reporting/vs-report/:formMode/:vsRptId" component={addEditVerificationScheduleReport}/>
      <Route path="/reporting/vs-report/:formMode" component={addEditVerificationScheduleReport}/>

      <Route exact path="/reporting/view-linked-activity/:vaId" component={ViewLinkedActivity}/>

      <Route path="/reporting/va-report-search" component={Reporting}/>
      <Route exact path="/reporting/view/:rptId" component={ViewReport}/>
      <Route exact path="/reporting/:formMode/:rptId" component={addEditReport}/>
      <Route path="/reporting/view-asset-verification" component={ViewAssetVerification}/>
      <Route path="/reporting/view-periodic-verification" component={ViewPeriodicVerification}/>
      <Route path="/reporting/:formMode" component={addEditReport}/>

      <Route path="/documentation/file-management" component={FileManagement}/>
      <Route path="/documentation/:parentId" component={Documentation}/>
      <Route path="/documentation" component={Documentation}/>

      {/* <Route path="/statistics/verification-schedule-dash" component={VerificationScheduleDashExpanded}/> */}
      <Route path="/statistics/verification-schedule-dash" component={VerificationScheduleStatsDashExpAlt}/>
      <Route path="/seces-status" component={SeceStatus}/>
      
      {/* <Route path="/statistics" component={Statistics}/> */}
      <Route path="/statistics" component={StatisticsAlt}/>
      <Route path="/comparison-engine" component={ComparisonEngine}/>

      {/* <Route path="/bowtie-dashboard" component={BowtieDash}/> */}
      <Route path="/bowtie-dashboard" component={BowtieDashAlt}/>
      
      {/* <Route path="/bowtie/view/:mahId" component={Bowtie}/> */}
      <Route path="/bowtie/view/:mahId" component={BowtieAlt}/>

      <Route path="/info-matrix/:formMode/:imId" component={AddEditInfoMatrix}/>
      <Route path="/info-matrix/:formMode" component={AddEditInfoMatrix}/>
      <Route path="/info-matrixes" component={InfoMatrix}/>

      <Route path="/access-log" component={AccessLog}/>
      <Route path="/notifications" component={Notifications}/>
      <Route path="/notification/change/:notificationId" component={NotificationUpdateDiff}/>

      <Route path="/notice/:formMode/:noticeId" component={AddEditNotice}/>
      <Route path="/notice/:formMode" component={AddEditNotice}/>
      <Route path="/notices/view/:userId" component={ViewNotice}/>
      <Route path="/notices" component={Notices}/>

      <Route exact path="/system-settings" component={SystemSettings}/>
      <Route exact path="/system-settings/variables/:sysVarTypeId" component={SystemVariables}/>
      <Route path="/system-settings/variables/:sysVarTypeId/:formMode" component={AddEditSystemVariables}/>

      <Route exact path="/admin/batch-operations" component={BatchOperations}/>

      <Route component={RouteNotFound}/>
    </Switch>
  )
});

export default Routes;