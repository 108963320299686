/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormError from "../global/FormError";
import { Link, useParams, useHistory } from 'react-router-dom';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { omit } from 'lodash';
import { AuthContext } from "../../context/authContext";
import {NavContext} from '../../context/navContext';
import {createUserTypeAndRoleObject} from "../../utils";

const validation = (barsByOrderNo) => {
    let validation = {};
    let sharedValidationObject = {
        bar_title: Yup.string().label("bar_title").required("Barrier Name is required"),
        bar_order_no_exist: Yup.boolean().default(barsByOrderNo > 0),
        bar_order_no: Yup.number()
            .integer("Order number must be an integer")
            .positive("Order number must be positive")
            .min(1, "Order number must be greater than 0")
            .when('bar_order_no_exist', {
                is: true,
                then: Yup.number().test("bar_order_no", "Order number already exists", function (value) {
                    const { path, createError } = this;
                    return createError({ path, message: "Order number already exists" });
                }),
            }),
    };
    validation = Yup.object().shape({
        ...sharedValidationObject,
    })
    return validation;
}

const CREATE_BARRIER = gql`
    mutation AddBarrier($bar: CreateBarrier!) {
        createBarrier(bar: $bar) {
            _id
            success
            status_code
            message
        }
    }
`;

const UPDATE_BARRIER = gql`
    mutation UpdateBarrier($id: String!, $bar: UpdateBarrierInput!, $userId: String) {
        updateBarrier(_id: $id, bar: $bar, userId: $userId) {
            ag_id{
                _id
            }
            bar_title 
            bar_notes
            bar_order_no
            success
            status_code
            message
        }
    }
`;

const BARRIER_QUERY = gql`
query Barrier($barrierId: ID!){
    barrier(_id: $barrierId) {
        dh_id{
            _id
            dh_name
        }
        ag_id{
            _id
        }
        installation_id{
            _id
        }
        bar_title 
        bar_notes
        bar_order_no
    }
}
`;

const BARRIERS_BY_ORDER_NO_QUERY = gql`
query BarriersByOrderNo($orderNo: Int!, $instId: String!){
    barriersByOrderNo(orderNo: $orderNo, instId: $instId)
}`;

const AddEditBarrier = (props) => {
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
    const [drDnInstallation, setDrDnInstallation] = useState({});

    const [showHelp, setShowHelp] = useState(false);

    const [barsByOrderNoCount, setBarsByOrderNoCount] = useState(undefined);
    const [enteredOrderNo, setEnteredOrderNo] = useState(undefined);

    const [formInitValues, setFormInitValues] = useState({
        dh_id: "",
        ag_id: "",
        installation_id: "",
        bar_title: "",
        bar_notes: "",
        bar_order_no: 0
    });

    const { formMode, id, assetGroupId, installationId, barrierId = "" } = useParams();
    const { location: { state = "" } } = props ? props : {};

    const authContext = useContext(AuthContext);
    const navContext = useContext(NavContext);
    const history = useHistory();

    const [createBarrier, { data: createBarrierData }] = useMutation(CREATE_BARRIER);
    const [updateBarrier, { data: updateBarrierData }] = useMutation(UPDATE_BARRIER);
    const [barriersByOrderNo, { data: barsByOrderNoData }] = useLazyQuery(BARRIERS_BY_ORDER_NO_QUERY);
    const [barrier, { data: BarrierData }] = useLazyQuery(BARRIER_QUERY);

    useEffect(() => {
    if (authContext && authContext.user) {
        let result = createUserTypeAndRoleObject(authContext);
        setUserTypeAndRoleObject(result);
    }
    }, [authContext]);

    useEffect(() => {
        const { installation } = navContext;
        const { id: prevId } = drDnInstallation;

        if (prevId && installation.id && prevId !== installation.id) {
            history.push('/');
        }
        setDrDnInstallation(installation);
    }, [navContext]);

    useEffect(() => {
    if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
        history.push('/access-permissions');
    }
    }, [userTypeAndRoleObject]);

    useEffect(() => {
        if (formMode === "edit" && barrierId !== "") {
            barrier({ variables: { barrierId }, errorPolicy: 'all' });
        }
    }, []);

    useEffect(() => {
        if (BarrierData && BarrierData.barrier) {
            setFormInitValues({
                dh_id: BarrierData.barrier.dh_id._id,
                ag_id: BarrierData.barrier.ag_id,
                installation_id: BarrierData.barrier.installation_id,
                bar_title: BarrierData.barrier.bar_title,
                bar_notes: BarrierData.barrier.bar_notes,
                bar_order_no: Number(BarrierData.barrier.bar_order_no)
            })
        }
    }, [BarrierData]);

    useEffect(() => {
        if (createBarrierData) {
            const {success, message} = createBarrierData.createBarrier;
            if (!success) {
                alert(message);
            }
            if (success) {
                history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barriers`);       
            }
        }
    }, [createBarrierData, assetGroupId]);

    useEffect(() => {
        if (updateBarrierData) {
            const { success, message } = updateBarrierData.updateBarrier;
            if (!success) {
                alert(message);
            }
            if (success) {
                history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barriers`);
            }
        }
    }, [updateBarrierData, assetGroupId]);

    useEffect(() => {
        if (barsByOrderNoData) {
            if (formMode === "edit" && enteredOrderNo === formInitValues.bar_order_no) {
                setBarsByOrderNoCount(0);
            } else {
                setBarsByOrderNoCount(barsByOrderNoData.barriersByOrderNo);
            }
        }
    }, [barsByOrderNoData]);

    const handleBarOrderNoChange = (event, installationId) => {
        let orderNo = parseInt(event.target.value);
        setEnteredOrderNo(orderNo);
        barriersByOrderNo({ variables: { orderNo: orderNo, instId: installationId }, errorPolicy: 'all' });
    }

    return (
        <div className="w-full px-8 pb-8">
            <div className="pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{formMode} Barrier <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

                {showHelp &&
                <div>
                    <p>Enter the details into the required fields.</p>
                    <p>Once complete, click '<span className="capitalize">{formMode}</span> Barrier' which will initiate a notification for review.</p>
                </div>
                }                
            </div>

            <Formik
                enableReinitialize
                initialValues={omit(formInitValues, '__typename')}
                validationSchema={validation(barsByOrderNoCount)}
                onSubmit={(values, actions) => {
                    const tempValues = { ...values };                   
                    const { _id: userId = "" } = authContext.user;

                    if (id !== undefined) {
                        tempValues.dh_id = id;
                    }

                    if (assetGroupId !== undefined) {
                        tempValues.ag_id = assetGroupId;
                    }

                    if (installationId !== undefined) {
                        tempValues.installation_id = installationId;
                    }

                    if (formMode === 'add') {                   
                        tempValues.bar_added_id = userId;
                        createBarrier({ variables: { bar: tempValues } })
                    }

                    if (formMode === 'edit') {
                        updateBarrier({ variables: { id: barrierId, bar: tempValues, userId: userId} })
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit}>

                        <div className="my-4">
                            {displayBackLink(state, id, assetGroupId, installationId)}
                        </div>

                        <div>
                            <label htmlFor="bar_title" className="block mb-1 text-blue-900 font-semibold">Barrier Name*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="bar_title"
                                id="bar_title"
                                placeholder="Barrier Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.bar_title}
                            />
                            <FormError touched={touched.bar_title} message={errors.bar_title} />
                        </div>

                        <div className="mb-4">
                            <label htmlFor="bar_notes" className="block mb-1 text-blue-900 font-semibold">Notes</label>
                            <textarea
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="bar_notes"
                                id="bar_notes"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Notes"
                                value={values.bar_notes}
                            />
                        </div>

                        <div className="mb-4">
                            <label htmlFor="bar_order_no" className="block mb-1 text-blue-900 font-semibold">Order No.</label>
                            <input
                                type="number"
                                className="form-control block w-full md:w-1/2"
                                name="bar_order_no"
                                id="bar_order_no"
                                onChange={(event) => { handleBarOrderNoChange(event, installationId); handleChange(event) }}
                                onBlur={handleBlur}
                                value={values.bar_order_no}
                            />
                            <FormError touched={touched.bar_order_no} message={errors.bar_order_no} />
                        </div>

                        <button type="submit" className="mt-3 button-red capitalize">{formMode} Barrier</button>
                    </form>
                )}
            </Formik>

        </div>
    )
}

function displayBackLink(state, id, assetGroupId, installationId) {
    return (
        <Link to={{
            pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barriers` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
            state: state && state.archivedStatus
        }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
    )
}

export default AddEditBarrier;
