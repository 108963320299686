/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

import React, {useState, useEffect, useContext} from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormError from "../global/FormError";
import { Link, useParams, useHistory } from 'react-router-dom';
import {gql, useMutation, useLazyQuery} from '@apollo/client';
import {omit} from 'lodash';
import {AuthContext} from "../../context/authContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileUpload from '../global/FileUpload';
import {parseJSONSafely, updateAttachmentData, createUserTypeAndRoleObject} from "../../utils";
import {removeLastNthParamFrmUrl } from '../../utils';
import {NavContext} from '../../context/navContext';

const {REACT_APP_FILE_MANAGER_API} = process.env;

const validation = (mahCount) => {
    let validation = {};
    let sharedValidationObject = {
        mah_ref_no_exist: Yup.boolean().default(mahCount > 0),
        mah_client_ref: Yup.string()
            .label("mah_client_ref")
            .required("Major Accident Hazard Ref No. is required")
            .when('mah_ref_no_exist', {
                is: true,
                then: Yup.string().test("mah_client_ref", "Major Accident Hazard Ref No. already exists", function (value) {
                    const { path, createError } = this;
                    return createError({ path, message: "Major Accident Hazard Ref No. already exists" });
                }),
            }),
        mah_title: Yup.string().label("mah_title").required("Major Accident Hazard Title is required"),
    };
    validation = Yup.object().shape({
        ...sharedValidationObject,
    })
    return validation;
}

const MAH_QUERY = gql`
    query MajorAccidentHazard($mahId: ID!){
        majorAccidentHazard(_id: $mahId) {
          dh_id{
              _id
              dh_name
          }
          ag_id
          installation_id
          mah_client_ref
          mah_title
          mah_notes
          mah_attachment
        }
    }`;

  const CREATE_MAH = gql`
    mutation AddMajorAccidentHazard($mah: CreateMajorAccidentHazard!, $seceId: String) {
        createMajorAccidentHazard(mah: $mah, seceId: $seceId) {
            _id
            dh_id{
                dh_name
            }
            mah_client_ref
            mah_title
            mah_notes
            mah_attachment
            success
            status_code
            message 
        }
    }
  `;

  const UPDATE_MAH = gql`
    mutation UpdateMajorAccidentHazard($id: String!, $userId: String, $mah: UpdateMajorAccidentHazard!, $seceId: String) {
        updateMajorAccidentHazard(_id: $id, userId: $userId, mah: $mah, seceId: $seceId) {
            _id
            dh_id{
                dh_name
            }
            mah_client_ref
            mah_title
            mah_notes 
            mah_attachment
            success
            status_code
            message
        }
    }`;

const MAH_BY_REF_NO_COUNT_QUERY = gql`
query MajorAccidentHazardCount($refNo: String!, $instId: String!){
    majorAccidentHazardCount(refNo: $refNo, instId: $instId)
}`;

const AddEditMAH = (props) => { 
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

    const [showHelp, setShowHelp] = useState(false);

    const [mahRefNoCount, setMahRefNoCount] = useState(undefined);
    const [enteredMahRefNo, setEnteredMahRefNo] = useState(undefined);

    const [installId, setInstallationId] = useState("");
    const [assetGpId, setAssetGroupId] = useState("");

    const [drDnInstallation, setDrDnInstallation] = useState({});

    const { formMode, id, assetGroupId, installationId, seceId, mahId } = useParams();
    const { location: { state = "" }, match: {url = ""}} = props ? props : {};

    const authContext = useContext(AuthContext);
    const navContext = useContext(NavContext);
    const history = useHistory();

    const [formInitValues, setFormInitValues] = useState({
        dh_id: id,
        ag_id: assetGroupId,
        installation_id: "",
        mah_client_ref: "",
        mah_title: "",
        mah_notes: "",
        mah_attachment: "[]",
    });

    const [majorAccidentHazard, { data: mahData }] = useLazyQuery(MAH_QUERY);
    const [majorAccidentHazardCount, { data: mahCountData }] = useLazyQuery(MAH_BY_REF_NO_COUNT_QUERY);
    const [createMajorAccidentHazard, { data: createMajorAccidentHazardData }] = useMutation(CREATE_MAH);
    const [updateMajorAccidentHazard, { data: updateMajorAccidentHazardData }] = useMutation(UPDATE_MAH);

    useEffect(() => {
    if (authContext && authContext.user) {
        let result = createUserTypeAndRoleObject(authContext);
        setUserTypeAndRoleObject(result);
    }
    }, [authContext]);

    useEffect(() => {
       const { installation } = navContext;
        const { id: prevId } = drDnInstallation;
    
        if (prevId && installation.id && prevId !== installation.id) {
          history.push('/');
        }
        setDrDnInstallation(installation);
    }, [navContext]);

    useEffect(() => {
    if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
        history.push('/access-permissions');
    }
    }, [userTypeAndRoleObject]);

    useEffect(() => {
        if (formMode ==="add") {
            setInstallationId(installationId);
        };    
        if (formMode ==="edit") {
            majorAccidentHazard({ variables: { mahId: mahId }, errorPolicy: 'all' });
        }
    }, []);


    useEffect(() => {
        if (mahData && mahData.majorAccidentHazard) {
            let tempMahData = mahData.majorAccidentHazard;

            const { _id: dutyHolderId = "" } = tempMahData.dh_id ? tempMahData.dh_id : {};
            let assetGpId = tempMahData.ag_id ? tempMahData.ag_id : "";
            let installId = tempMahData.installation_id ? tempMahData.installation_id : "";

            let attachment = tempMahData.mah_attachment && tempMahData.mah_attachment !== "" ? tempMahData.mah_attachment : "[]";

            setFormInitValues({
                dh_id: dutyHolderId,
                ag_id: assetGpId,
                installation_id: installId,
                mah_client_ref: tempMahData.mah_client_ref ? tempMahData.mah_client_ref : "",
                mah_title: tempMahData.mah_title,
                mah_notes: tempMahData.mah_notes,
                mah_attachment: attachment,
            });

            setInstallationId(installId);
            setAssetGroupId(assetGpId);
        }
    }, [mahData]);

    useEffect(() => {
        if (createMajorAccidentHazardData) {
            const { success, message } = createMajorAccidentHazardData.createMajorAccidentHazard;
            if (!success) {
                alert(message);
            }
            if (success) {
                history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/mahs`);
            }
        }
    }, [createMajorAccidentHazardData, assetGroupId, installationId, seceId]);

    useEffect(() => {
        if (updateMajorAccidentHazardData) {
            const { success, message } = updateMajorAccidentHazardData.updateMajorAccidentHazard;
            if (!success) {
                alert(message);
            }
            if (success) {
                history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/mahs`);
            }
        }
    }, [updateMajorAccidentHazardData, assetGroupId, installationId]);

    useEffect(() => {
        if (mahCountData) {
            if (formMode === "edit" && enteredMahRefNo === formInitValues.mah_client_ref) {
                setMahRefNoCount(0);
            } else {
                setMahRefNoCount(mahCountData.majorAccidentHazardCount);
            }
        }
    }, [mahCountData]);

    const handleMahRefNoChange = (event, installationId) => {
        let refNo = event.target.value;
        setEnteredMahRefNo(refNo);
        majorAccidentHazardCount({ variables: { refNo: refNo, instId: installationId }, errorPolicy: 'all' });
    }
    
    let tempMahAttachFileList = [];
    const handleMahAttachFilesDescription = (item) => {
        let index = tempMahAttachFileList.findIndex(f => f.name === item.name);
        if (index >= 0) {
            tempMahAttachFileList[index] = item;
        }else{
            tempMahAttachFileList.push(item);
        }
    };

    return (
        <div className="w-full px-8 pb-8">

            <div className="pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{formMode} Major Accident Hazard <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

                {showHelp &&
                <div>
                    <p>Enter the details into the required fields, including adding attachments by clicking on 'Attach Files'.</p>
                    <p>Once complete, click '<span className="capitalize">{formMode}</span> Major Accident Hazard' which will initiate a notification for review.</p>
                </div>
                }                
            </div>

            <Formik
                enableReinitialize
                initialValues={omit(formInitValues, '__typename')}
                validationSchema={validation(mahRefNoCount)}
                onSubmit={(values, actions) => {
                    let tempValues = { ...values };
                    const { _id: userId = "" } = authContext.user ? authContext.user : {};

                    tempValues.mah_attachment = updateAttachmentData(tempValues.mah_attachment, tempMahAttachFileList);
                    
                    if (formMode === 'add') {
                        tempValues.installation_id = installId;
                        tempValues.mah_added_id = userId;
                        tempValues.mah_base_path = removeLastNthParamFrmUrl(url, 1);
                        createMajorAccidentHazard({ variables: { mah: tempValues, seceId: seceId } });
                    }

                    if (formMode === 'edit') {
                        tempValues.installation_id = installId;
                        tempValues.ag_id = assetGpId;
                        updateMajorAccidentHazard({ variables: { id: mahId, userId: userId, mah: tempValues } });
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    setFieldTouched,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit}>

                        <div className="my-4">
                            {displayBackLink(state, id, assetGroupId, installationId, seceId)}
                        </div>

                        <div>
                            <label htmlFor="mah_client_ref" className="block mb-1 text-blue-900 font-semibold">Major Accident Hazard Ref No.*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="mah_client_ref"
                                id="mah_client_ref"
                                placeholder="Ref No."
                                onChange={(event) => { handleChange(event); handleMahRefNoChange(event, installationId); }}
                                onBlur={handleBlur}
                                value={values.mah_client_ref}
                            />
                            <FormError touched={touched.mah_client_ref} message={errors.mah_client_ref} />
                        </div>

                        <div>
                            <label htmlFor="mah_title" className="block mb-1 text-blue-900 font-semibold">Major Accident Hazard Title*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="mah_title"
                                id="mah_title"
                                placeholder="Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.mah_title}
                            />
                            <FormError touched={touched.mah_title} message={errors.mah_title} />
                        </div>

                        <div className="mb-4">
                            <label htmlFor="mah_notes" className="block mb-1 text-blue-900 font-semibold">Notes</label>
                            <textarea
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="mah_notes"
                                id="mah_notes"
                                placeholder="Notes"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.mah_notes}
                            />
                        </div>

                        <div>
                            <label htmlFor="mah_attachment" className="block mb-1 text-blue-900 font-semibold">Attachments</label>
                            {authContext.user && authContext.user.user_home_dir &&
                                <FileUpload
                                    onAttach={(files) => setFieldValue('mah_attachment', JSON.stringify(files))}
                                    id='fileManager'
                                    buttonText='Attach Files'
                                    apiURL={REACT_APP_FILE_MANAGER_API}
                                    toolbarText='Attach'
                                    homeDir={`/${authContext.user.user_home_dir}/`}
                                    showDescription={true}
                                    handleFileDescription={handleMahAttachFilesDescription}
                                    tempFileList={tempMahAttachFileList}
                                    {...(formMode === 'edit' && { attachmentList: parseJSONSafely(values.mah_attachment) ? parseJSONSafely(values.mah_attachment) : [] })}
                                />
                            }
                            <FormError touched={touched.mah_attachment} message={errors.mah_attachment} />
                        </div>

                        <button type="submit" className="mt-3 button-red capitalize">{formMode} Major Accident Hazard</button>

                    </form>
                )}
            </Formik>

        </div>
    )

}

function displayBackLink(state, id, assetGroupId, installationId, barrierId) {

      return (
        <Link to={{
          pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/mahs` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
          state: state && state.archivedStatus
        }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
      )

}

export default AddEditMAH;
