/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormError from "../global/FormError";
import { Link, useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useLazyQuery } from '@apollo/client';
import {omit, lowerCase, orderBy} from 'lodash';
import {AuthContext} from "../../context/authContext";
import {createUserTypeAndRoleObject} from "../../utils/index";
import {VerificationContext} from "../../context/verificationContext";
import {
    SCHEDULE_SYSTEM_VARIABLE_TYPE_ID, 
    CURRENT_STATUS_SCHEDULE_SYS_VAR_TYPE_ID,
    PERIODICITY_SYSTEM_VARIABLE_TYPE_ID, 
    MEETING_SCHEDULE_TYPE_ID,
    OFFSHORE_SCHEDULE_TYPE_ID,
    ADHOC_SCHEDULE_TYPE_ID,
    NOTIFICATION_STATUS_APPROVED,
    SCHEDULE_FORM_ORIGIN_VS_PLANNER,
    SCHEDULE_FORM_ORIGIN_VS_REGISTER,
    SCHEDULE_FORM_ORIGIN_VS_AR_REGISTER
} from '../../constants/';

import makeAnimated from 'react-select/animated';

import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const Validation = Yup.object().shape({
    as_title: Yup.string().label("as_title").required("Schedule Title is required"),
    as_periodicity: Yup.string().label("as_periodicity").required("Schedule Periodicity is required"),
    as_type: Yup.string().label("as_type").required("Schedule Type is required"),
    dh_id: Yup.string().label("dh_id").required("Duty Holder is required"),
    installation_ids: Yup.string().label("installation_ids").required("Installation is required"),
    //sece_ids: Yup.array().when('as_type', {
    //    is: (as_type) => as_type !== MEETING_SCHEDULE_TYPE_ID,
    //    then: Yup.array().min(1, "Select at least 1 SECE"),
    //}),
    sece_ids: Yup.string().label("sece_ids").required("Sece is required"),
    as_start_date: Yup.date().label("as_start_date").required("Start Date is required"),
    as_end_date: Yup.date().label("as_end_date").required("End Date is required").test("as_end_date", "End date cannot be before start date", function (value) {
        const { path, createError, parent } = this;
        let startDate = parent.as_start_date;
        return moment(value).isBefore(startDate) ? createError({ path, message: "End date cannot be before start date" }) : true;
    }),
});

const VERIFICATION_SCHEDULE_ID_QUERY = gql`
query VerificationSchedule($vsId: ID!){
    verificationSchedule(_id: $vsId){
        _id
        vs_title
        vs_periodicity{ _id}
        schedule_type_id{
          _id
          sysvar_title
        }
        dh_id{
          _id
          dh_name
        }
        installation_ids{
          _id
          installation_title
          installation_archived
          installation_status
        }
        sece_ids{
          _id
          sece_title
          sece_client_ref
          sece_archived
          sece_status
        }
        va_ids{
          _id
          va_short_desc
        }
        ra_ids{
          _id
          ra_title
        }
        verifier_ids{
          _id
          user_fname
          user_lname
        }
        vs_current_status{
          _id
          sysvar_title
        }
        vs_start_date_time
        vs_end_date_time
        vs_added_date
        vs_added_id{
            _id
        }
        vs_archived
    }
}`;

const SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS = gql`
    query SystemVariablesBySysVarTypeIds($sysVarTypeIds: [ID!]){
        systemVariablesBySysVarTypeIds(sysVarTypeIds: $sysVarTypeIds){
            _id
            sysvar_title
            sysvar_archived
            sysvartype_id{
                _id
                sysvartype_title
            }
        }
}`;

const DUTY_HOLDERS_QUERY = gql`
    query {
        dutyHolders {
            _id
            dh_name
            dh_status
            dh_added_date
            dh_archived
        }
}`;

const INSTALLATIONS_BY_DH_ID_QUERY = gql`
query installationsByDutyHolderId($dhId: ID){
    installationsByDutyHolderId(dhId: $dhId) {
      _id
      installation_title
      installation_archived
      installation_status
  }
}`;

const SECES_BY_DH_ID_AND_ISTALL_IDS_QUERY = gql`
query SecesByDHIdAndInstallIds($dhId: ID, $installIds: [ID]){
  secesByDHIdAndInstallIds(dhId: $dhId, installIds: $installIds) {
       _id
       sece_title
       sece_order_no
       sece_archived
       sece_status
       sece_client_ref
    }
}`;

const AddEditVerificationScheduleStep1 = (props) => {
    const [selectedDutyHolderId, setSelectedDutyHolderId] = useState("");
    const [scheduleTypeList, setScheduleTypeList] = useState([]);
    const [periodicityList, setPeriodicityList] = useState([]);
    const [dutyHolderList, setDutyHolderList] = useState([]);

    const [installationList, setInstallationList] = useState([]);
    const [selectedInstallIdList, setSelectedInstallIdList] = useState([]);

    const [seceList, setSeceList] = useState([]);
    const [scheduleStatusList, setScheduleStatusList] = useState([]);

    const [isBackFromStep1, setIsBackFromStep1] = useState(false);

    const [formInitValues, setFormInitialValues] = useState({
        as_title: "",
        as_periodicity: "",
        as_type: "",
        dh_id: "",
        installation_ids: [],
        sece_ids: [],
        as_start_date: "",
        as_end_date: "",
        schedule_status: ""
    });

    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

    const authContext = useContext(AuthContext);
    const verificationContext = useContext(VerificationContext);

    //form add or edit state passed from link
    const { formMode = "", vsId = "" } = useParams();
    const history = useHistory();

    const { location: { state } } = props ? props : {};

    const [verificationSchedule, {data: vsData}] = useLazyQuery(VERIFICATION_SCHEDULE_ID_QUERY);
    const [systemVariablesBySysVarTypeIds, {data: sysVarsData}] = useLazyQuery(SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS);
    const [dutyHolders, {data: dutyHoldersData}] = useLazyQuery(DUTY_HOLDERS_QUERY);
    const [installationsByDutyHolderId, {data: installsData}] = useLazyQuery(INSTALLATIONS_BY_DH_ID_QUERY);
    const [secesByDHIdAndInstallIds, {data: secesData}] = useLazyQuery(SECES_BY_DH_ID_AND_ISTALL_IDS_QUERY);

    const sysVarTypeIds = [
        SCHEDULE_SYSTEM_VARIABLE_TYPE_ID, 
        PERIODICITY_SYSTEM_VARIABLE_TYPE_ID,
        CURRENT_STATUS_SCHEDULE_SYS_VAR_TYPE_ID
    ];

    useEffect(() => {
        if (authContext && authContext.user){
            let result = createUserTypeAndRoleObject(authContext);
            setUserTypeAndRoleObject(result);
        }
    }, [authContext]);

    useEffect(() => {
        if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
            history.push('/access-permissions');
        }
    }, [userTypeAndRoleObject]);

    useEffect(() => {
        //setPeriodicityList(periodicity);
        dutyHolders({ variables: {}, errorPolicy: 'all' });
        systemVariablesBySysVarTypeIds({ variables: { sysVarTypeIds: sysVarTypeIds }, errorPolicy: 'all' });
        setInitFormValuesOnBackFromNav();

        if (formMode === "edit" && vsId !== "") {
            verificationSchedule({ variables: { vsId: vsId }, errorPolicy: 'all' })
        }
    }, []);

    useEffect(() => {
        if (selectedDutyHolderId !== '') {
            installationsByDutyHolderId({ variables: { dhId: selectedDutyHolderId }, errorPolicy: 'all' });  
            setSeceList([]); // This clears out any existing seces  
        }
    }, [selectedDutyHolderId]);

    useEffect(() => {
        if (selectedDutyHolderId && selectedDutyHolderId !=="" && selectedInstallIdList) {
            secesByDHIdAndInstallIds({ variables: { dhId: selectedDutyHolderId, installIds: selectedInstallIdList }, errorPolicy: 'all' }); 
        }    
    }, [selectedInstallIdList]);

    useEffect(() => {
        if (dutyHoldersData && dutyHoldersData.dutyHolders) {
            let list = dutyHoldersData.dutyHolders;
            let newDutyHolderList = list.filter(dh => dh.dh_status === "approved" && dh.dh_archived !== true);
            setDutyHolderList(newDutyHolderList);
        }
    }, [dutyHoldersData]);

    useEffect(() => {
        if (sysVarsData && sysVarsData.systemVariablesBySysVarTypeIds) {
            formatSysVarDataAndUpdateStates(sysVarsData.systemVariablesBySysVarTypeIds,
                setScheduleTypeList,
                setPeriodicityList,
                setScheduleStatusList,
                SCHEDULE_SYSTEM_VARIABLE_TYPE_ID,
                PERIODICITY_SYSTEM_VARIABLE_TYPE_ID,
                CURRENT_STATUS_SCHEDULE_SYS_VAR_TYPE_ID);
        }
    }, [sysVarsData]);

    useEffect(() => {
        if (installsData && installsData.installationsByDutyHolderId) {
            let tempList = formatInstallationData(installsData.installationsByDutyHolderId);
            setInstallationList(tempList);
            if (formMode === "add" &&  isBackFromStep1) {
                let editInstallList = formatInstallationEditData(tempList, formInitValues.installation_ids)
                setFormInitialValues({
                    ...formInitValues,
                    installation_ids: editInstallList
                });     
            }
        }
    }, [installsData]);

    useEffect(() => {
        if (secesData && secesData.secesByDHIdAndInstallIds) {
            let unOrderedList = secesData.secesByDHIdAndInstallIds;
            let sortedSeces = orderBy(unOrderedList, ['sece_order_no'], ['asc']);
            let tempList = formatSeceData(sortedSeces);
            setSeceList(tempList);
            if (formMode === "add" &&  isBackFromStep1) {
                let editSeceList = formatSeceEditData(tempList, formInitValues.sece_ids)
                setFormInitialValues({
                    ...formInitValues,                  
                    sece_ids: editSeceList
                });  
            }
        }
    }, [secesData]);

    useEffect(() => {
        if (installationList.length && seceList.length && isBackFromStep1) {
            setIsBackFromStep1(false);
        }
    }, [installationList, seceList]);

    useEffect(() => {
        if (vsData && vsData.verificationSchedule) {
            updateVsDataForEditOperation(
                vsData.verificationSchedule,
                formInitValues, 
                setFormInitialValues,             
                setSelectedDutyHolderId, 
                setSelectedInstallIdList, 
                verificationContext)
        }
    }, [vsData]);

    const setInitFormValuesOnBackFromNav = () => {
        const { step1FormData } = verificationContext;

        if (step1FormData.vs_title) {
            let initValues = {
                ...formInitValues,
                as_title: step1FormData.vs_title,
                as_periodicity: step1FormData.vs_periodicity,
                as_type: step1FormData.schedule_type_id,
                dh_id: step1FormData.dh_id,
                installation_ids: step1FormData.installation_ids,
                sece_ids: step1FormData.sece_ids,
                as_start_date: new Date(step1FormData.vs_start_date_time),
                as_end_date: new Date(step1FormData.vs_end_date_time)
            }

            setFormInitialValues(initValues);
            setSelectedDutyHolderId(step1FormData.dh_id);

            setSelectedInstallIdList(step1FormData.installation_ids);// Needed to update to enable fetching seces on navigate back from Step 2    
            setIsBackFromStep1(true);

            //Make Sure You Clear The Context!!!!
            verificationContext.clearFormData();
        }
    }

    const handleDutyHolderChange = (event) => {
        let value = event.target.value;
        setSelectedDutyHolderId(value);
        setInstallationList([]);
        setSeceList([])
    };

    const handleInstallationChange = (installations, scheduleType) => {
        let isSingleSelect = ((scheduleType === OFFSHORE_SCHEDULE_TYPE_ID) || (scheduleType === ADHOC_SCHEDULE_TYPE_ID));
        if (installations) {
            let installIds = isSingleSelect ? [installations.value] : installations.map(i => i.value);
            setSelectedInstallIdList(installIds);
        } else {
            setSelectedInstallIdList([]);
        }
    }

    const moveToStep2 = (formMode, vsId) => {
        if (formMode === "add") {
            history.push(`/verification/${formMode}/step2`, { formMode: formMode });
        } else {
            history.push(`/verification/${formMode}/step2/${vsId}`, { formMode: formMode });
        }
    };

    const moveToBack = () => {
        console.log("verificationContext.clearFormData()>>>>359");
        verificationContext.clearFormData(); //Clears the context data when moving back to the verification page
    };

    return (
        <div className="w-full px-8 pb-8">

            <div className="pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{formMode} Verification Schedule</h1>
            </div>

            <Formik
                enableReinitialize
                initialValues={omit(formInitValues, '__typename')}
                validationSchema={Validation}
                onSubmit={(values, actions) => {

                    let offshore = OFFSHORE_SCHEDULE_TYPE_ID;
                    let adhoc = ADHOC_SCHEDULE_TYPE_ID;

                    let installIds;
                    if (((values.as_type === offshore) || (values.as_type === adhoc))) {
                        installIds = Array.isArray(values.installation_ids) ? values.installation_ids : [values.installation_ids]
                    } else {
                        installIds = values.installation_ids;
                    }

                    let periodicity = periodicityList.find(p => p.id === values.as_periodicity);

                    const { period } = periodicity ? periodicity : {};

                    let step1FormData = {
                        vs_title: values.as_title,
                        vs_periodicity: values.as_periodicity,
                        vs_periodicity_title: period,
                        schedule_type_id: values.as_type,
                        dh_id: values.dh_id,
                        installation_ids: formatBackToIdList(installIds),
                        sece_ids: formatBackToIdList(values.sece_ids),
                        vs_start_date_time: values.as_start_date,
                        vs_end_date_time: values.as_end_date,
                        ...(values.schedule_status !== "" && {
                            vs_current_status: values.schedule_status,
                            vs_status: getStatusName(scheduleStatusList, values.schedule_status)
                        })
                    };

                    if (step1FormData && step1FormData.installation_ids.some(i => i === undefined)) {
                        return;
                    }
                    //console.log("step1FormData>>>>", step1FormData);

                    verificationContext.updateStep1FormData(step1FormData);
                    verificationContext.updateOriginStateData(state);
                    moveToStep2(formMode, vsId);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="my-4">
                            <Link to={createMoveBackPath(state, vsId)} onClick={() => {moveToBack()}} className="button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back</Link>
                        </div>

                        <div>
                            <label htmlFor="as_title" className="block mb-1 text-blue-900 font-semibold">Schedule Title*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="as_title"
                                id="as_title"
                                placeholder="Title"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.as_title}
                            />
                            <FormError touched={touched.as_title} message={errors.as_title} />
                        </div>

                        <div>
                            <label htmlFor="as_periodicity" className="block mb-1 text-blue-900 font-semibold">Schedule Periodicity*</label>
                            <select
                                className="form-control block w-full md:w-1/2"
                                name="as_periodicity"
                                id="as_periodicity"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.as_periodicity}
                            >
                                <option value={""}>Select Periodicity</option>
                                {periodicityList.map(item =>
                                    <option key={item.id} value={item.id}>{item.period}</option>
                                )}
                            </select>
                            <FormError touched={touched.as_periodicity} message={errors.as_periodicity} />
                        </div>
                        <div>
                            <label htmlFor="as_type" className="block mb-1 text-blue-900 font-semibold">Schedule Type*</label>
                            <select
                                className="form-control block w-full md:w-1/2"
                                name="as_type"
                                id="as_type"
                                onChange={(event) => {
                                    handleChange(event);
                                    setFieldValue('sece_ids', []);
                                }}
                                onBlur={handleBlur}
                                value={values.as_type}
                            >
                                <option value={""}>Select Type</option>
                                {scheduleTypeList.map(scheduleType =>
                                    <option value={scheduleType._id} key={scheduleType._id}>{scheduleType.sysvar_title}</option>
                                )}
                            </select>
                            <FormError touched={touched.as_type} message={errors.as_type} />
                        </div>

                        <div>
                            <label htmlFor="dh_id" className="block mb-1 text-blue-900 font-semibold">Duty Holder*</label>
                            <select
                                className="form-control block w-full md:w-1/2"
                                name="dh_id"
                                id="dh_id"
                                onChange={(event) => {
                                    handleChange(event);
                                    handleDutyHolderChange(event);
                                    setFieldValue('installation_ids', []);
                                    setFieldValue('sece_ids', []);
                                }}
                                onBlur={handleBlur}
                                value={values.dh_id}
                            >
                                <option value={""}>Select Duty Holder</option>
                                {dutyHolderList.map(dh =>
                                    <option key={dh._id} value={dh._id}>{dh.dh_name}</option>
                                )}
                            </select>
                            <FormError touched={touched.dh_id} message={errors.dh_id} />
                        </div>

                        <div>
                            <label htmlFor="installation_ids" className="block mb-1 text-blue-900 font-semibold">Installations*</label>
                            <Select
                                id="installation_ids"
                                name="installation_ids"
                                aria-label="installations"
                                className="w-full md:w-1/2"
                                classNamePrefix="react-select"
                                options={installationList}
                                isMulti={!((values.as_type === OFFSHORE_SCHEDULE_TYPE_ID) || (values.as_type === ADHOC_SCHEDULE_TYPE_ID))}
                                isSearchable
                                components={makeAnimated()}
                                closeMenuOnSelect={((values.as_type === OFFSHORE_SCHEDULE_TYPE_ID) || (values.as_type === ADHOC_SCHEDULE_TYPE_ID))}
                                placeholder="Select Installations"
                                onChange={installation => {
                                    setFieldValue('installation_ids', (installation ? installation : []));
                                    handleInstallationChange(installation, values.as_type);  
                                    setFieldValue('sece_ids', []);  
                                }}
                                onBlur={installation => setFieldTouched('installation_ids', (installation ? installation : []))}
                                value={values.installation_ids}
                            />                          
                            <FormError touched={touched.installation_ids} message={errors.installation_ids} />
                        </div>

                        <div>
                            <label htmlFor="sece_ids" className="block mb-1 text-blue-900 font-semibold">SECE{values.as_type !== MEETING_SCHEDULE_TYPE_ID && "*"}</label>
                            <Select
                                isDisabled={values.as_type === MEETING_SCHEDULE_TYPE_ID}
                                id="sece_ids"
                                name="sece_ids"
                                aria-label="SECEs"
                                className="w-full md:w-1/2"
                                classNamePrefix="react-select"
                                options={seceList}
                                isMulti
                                isSearchable
                                components={makeAnimated()}
                                closeMenuOnSelect={false}
                                placeholder="Select SECEs"
                                onChange={sece => {setFieldValue('sece_ids', (sece ? sece : []))}}
                                onBlur={sece => {setFieldTouched('sece_ids', (sece ? sece : []))}}
                                value={values.sece_ids}
                                
                            />
                            <FormError touched={touched.sece_ids} message={errors.sece_ids} />
                        </div>

                        <div>
                            <label htmlFor="as_start_date" className="block mb-1 text-blue-900 font-semibold">Start Date/Time*</label>
                            <DatePicker
                                id="as_start_date"
                                selected={values.as_start_date}
                                dateFormat="dd MMMM Y - h:mm aa"
                                name="as_start_date"
                                onChange={date => setFieldValue('as_start_date', date)}
                                onBlur={handleBlur}
                                value={values.as_start_date}
                                className="form-control block w-full md:w-1/2"
                                showMonthDropdown
                                showYearDropdown
                                showTimeSelect
                                minDate={new Date()}
                                autoComplete="off"
                            />
                            <FormError touched={touched.as_start_date} message={errors.as_start_date} />
                        </div>

                        <div>
                            <label htmlFor="as_end_date" className="block mb-1 text-blue-900 font-semibold">End Date/Time*</label>
                            <DatePicker
                                id="as_end_date"
                                selected={values.as_end_date}
                                dateFormat="dd MMMM Y - h:mm aa"
                                name="as_end_date"
                                onChange={date => setFieldValue('as_end_date', date)}
                                onBlur={handleBlur}
                                value={values.as_end_date}
                                className="form-control block w-full md:w-1/2"
                                showMonthDropdown
                                showYearDropdown
                                showTimeSelect
                                minDate={new Date(values.as_start_date)}
                                {...(isSameDate(values.as_start_date, values.as_end_date) && {
                                    minTime: getMinTime(values.as_start_date),
                                    maxTime: getMaxTime(values.as_start_date)
                                })}
                                autoComplete="off"
                            />
                            <FormError touched={touched.as_end_date} message={errors.as_end_date} />
                        </div>

                        {formMode === "edit" &&
                            <div>
                                <label htmlFor="schedule_status" className="block mb-1 text-blue-900 font-semibold">Schedule Status</label>
                                <select
                                    className="form-control block w-full md:w-1/2"
                                    name="schedule_status"
                                    id="schedule_status"
                                    onChange={(event) => { handleChange(event) }}
                                    onBlur={handleBlur}
                                    value={values.schedule_status}
                                >
                                    <option value={""}>Select Schedule Status</option>
                                    {scheduleStatusList.map(status =>
                                        <option key={status.id} value={status.id}>{status.name}</option>
                                    )}
                                </select>
                            </div>
                        }

                        <button type="submit" className="mt-3 button-red capitalize">Next</button>
                    </form>
                )}
            </Formik>

        </div>
    )
}

function createMoveBackPath(state, vsId) {
    let pathParam = { pathname: "", state: {} }
    if (state) {
        const { vs_status = "", origin = "" } = state;
        switch (origin) {
            case SCHEDULE_FORM_ORIGIN_VS_PLANNER:
                pathParam = { ...pathParam, pathname: "/verification" }
                break;
            case SCHEDULE_FORM_ORIGIN_VS_REGISTER:
                pathParam = { ...pathParam, pathname: "/verification/verification-register", state: { vs_status: vs_status } }
                break;
            case SCHEDULE_FORM_ORIGIN_VS_AR_REGISTER:
                pathParam = { ...pathParam, pathname: `/verification/${vsId}/activity-register`, state: { vs_status: vs_status } }
                break;
            default:
                break;
        }
    }
    return pathParam
}

function getMinTime(date) {
    let newDate = new Date(date);
    let time  = newDate.getTime();
    return time;
}

function getMaxTime(date) {
    let newDate = new Date(date);
    newDate.setHours(newDate.getHours() + (23 - newDate.getHours()));
    let time  = newDate.getTime();
    return time;
}

function isSameDate(startDate, endDate) {
    let newStartDate = new Date(startDate);
    let newEndDate = new Date(endDate);
    return !moment(newEndDate).isAfter(newStartDate);
}

function formatSeceData(seces) {
    let formatedList = [];
    if (seces && seces.length) {
        let newSeceList = seces.filter(sece => sece.sece_archived !== true && sece.sece_status === NOTIFICATION_STATUS_APPROVED);
        newSeceList.forEach(sece => {
            let newSece = {
                value: sece._id,
                label: `${sece.sece_client_ref ? sece.sece_client_ref : "N/A"} : ${sece.sece_title}`
            }
            formatedList.push(newSece);
        });
    }
    return formatedList;
}

function formatInstallationData(installations) {
    let formatedList = [];
    if (installations && installations.length) {
        let newInstallationList = installations.filter(installation => installation.installation_archived !== true && installation.installation_status === NOTIFICATION_STATUS_APPROVED);
        newInstallationList.forEach(installation => {
            let newInstallation = {
                value : installation._id,
                label: installation.installation_title
            }
            formatedList.push(newInstallation);
        });
    }
    return formatedList;
}

function formatBackToIdList(items) {
    let formatedList = [];
    if (items && items.length) {
        items.forEach(item => {
            let id = item.value;
            formatedList.push(id);
        });
    }
    return formatedList;
}

function formatInstallationEditData(installationOptions, installationInitValues) {
    let newList = [];
    if (installationOptions && installationOptions.length && installationInitValues && installationInitValues.length) {
        installationInitValues.forEach(initInstall => {
            let install = installationOptions.find(install => install.value === initInstall);
            if (install) {
                newList.push(install);
            }
        });
    }
    return newList;
}

function formatSeceEditData(seceOptions, seceInitValues) {
    let newList = [];
    if (seceOptions && seceOptions.length && seceInitValues && seceInitValues.length) {
        seceInitValues.forEach(initSece => {
            let sece = seceOptions.find(sece => sece.value === initSece);
            if (sece) {
                newList.push(sece);
            }
        });
    }
    return newList;
}

function updateVsDataForEditOperation(vs, formInitValues, setFormInitialValues, setSelectedDutyHolderId, setSelectedInstallIdList, verificationContext) {

    let tempVsTypeId = vs.schedule_type_id ? vs.schedule_type_id._id : "";
    let tempDhId = vs.dh_id ? vs.dh_id._id : "";
    let tempInstallIds = vs.installation_ids ? vs.installation_ids : [];
    let tempSeceIds = vs.sece_ids ? vs.sece_ids : [];
    let tempVaIds = vs.va_ids ? vs.va_ids : [];
    let tempRaIds = vs.ra_ids ? vs.ra_ids : [];
    let tempVerifierIds = vs.verifier_ids ? vs.verifier_ids : [];

    let tempStatusId = vs.vs_current_status ? vs.vs_current_status._id : "";

    let startDate = parseInt(vs.vs_start_date_time); //Returning an epoch time in string, so need to convert to integer.
    let endDate = parseInt(vs.vs_end_date_time);

    //If The Schedule Type is Offshore or Adhoc Activity, then the install value iwll an object , else it will be an array.
    let installValue = ((tempVsTypeId === OFFSHORE_SCHEDULE_TYPE_ID) || (tempVsTypeId === ADHOC_SCHEDULE_TYPE_ID)) ? formatInstallationData(tempInstallIds)[0] : formatInstallationData(tempInstallIds)

    let initValues = {
        ...formInitValues,
        as_title: vs.vs_title,
        as_periodicity: vs.vs_periodicity &&  vs.vs_periodicity._id ? vs.vs_periodicity._id : "",
        as_type: tempVsTypeId,
        dh_id: tempDhId,
        installation_ids: installValue,
        sece_ids: formatSeceData(tempSeceIds),
        as_start_date: new Date(startDate),
        as_end_date: new Date(endDate),
        schedule_status : tempStatusId
    }

    let instIdList = installValue && installValue.length ? installValue.map(i => i.value): [];

    setFormInitialValues(initValues);
    setSelectedDutyHolderId(tempDhId);
    setSelectedInstallIdList(instIdList);

    let vaIds = [];
    let raIds = [];
    let verifierIds = [];

    tempVaIds.forEach(va => { vaIds.push(va._id); })
    tempRaIds.forEach(ra => { raIds.push(ra._id); })
    tempVerifierIds.forEach(ver => { verifierIds.push(ver._id); })

    let step2FormData = {
        va_ids: vaIds,
        ra_ids: raIds
    };

    let step3FormData = {
        verifier_ids: verifierIds
    };

    verificationContext.updateStep2FormData(step2FormData);
    verificationContext.updateStep3FormData(step3FormData);
}

function formatSysVarDataAndUpdateStates(list, setScheduleTypeList, setPeriodicityList, setScheduleStatusList, scheduleTypeId, periodicityTypeId, currentStatusTypeId) {
    let periodicities = [];
    let scheduleTypes = [];
    let currentStatusTypes = [];

    scheduleTypes = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === scheduleTypeId);
    let tempCurrentStatusTypes = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === currentStatusTypeId);
    let tempPeriodicities = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === periodicityTypeId);
    
    tempPeriodicities.forEach(period => {
        let newPeriodicity = {
            id: period._id,
            period: period.sysvar_title
        }
        periodicities.push(newPeriodicity);
    })

    tempCurrentStatusTypes.forEach(status => {
        let newCurrentStatus = {
            id: status._id,
            name: status.sysvar_title
        }
        currentStatusTypes.push(newCurrentStatus);
    })

    setScheduleTypeList(scheduleTypes);
    setPeriodicityList(periodicities);
    setScheduleStatusList(currentStatusTypes);
}

function getStatusName(statusList, statusId) {
    let statusName = "";

    if (statusList && statusId) {
        let status = statusList.find(s => s.id === statusId);
        let name = status.name ? status.name : "";
        statusName = lowerCase(name);
    }

    return statusName;
}

export default AddEditVerificationScheduleStep1;
