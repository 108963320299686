/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

import React, { useEffect, useState, useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormError from "../global/FormError";
import { Link, useParams, useHistory } from 'react-router-dom';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { omit } from 'lodash';
import FileUpload from '../global/FileUpload';
import { getSectionName, createQueryObject, parseJSONSafely, updateAttachmentData, removeLastNthParamFrmUrl, createUserTypeAndRoleObject } from "../../utils/index";
import { AuthContext } from "../../context/authContext";
import {NavContext} from '../../context/navContext';

const { REACT_APP_FILE_MANAGER_API } = process.env;

const validation = (raCount) => {
    let validation = {};
    let sharedValidationObject = {
        ra_ref_no_exist: Yup.boolean().default(raCount > 0),
        ra_client_ref: Yup.string()
            .label("ra_client_ref")
            .required("Client Ref is required")
            .when('ra_ref_no_exist', {
                is: true,
                then: Yup.string().test("ra_client_ref", "Client Ref already exists", function (value) {
                    const { path, createError } = this;
                    return createError({ path, message: "Client Ref already exists" });
                }),
            }),
        ra_title: Yup.string().label("ra_title").required("Title is required"),
        ra_desc: Yup.string().label("ra_desc").required("Description is required"),
        ra_sample_size: Yup.string().label("ra_sample_size").required("Sample Size is required"),
        ra_sample_quantity: Yup.string().label("ra_sample_quantity").required("Sample Quantity is required"),
    };
    validation = Yup.object().shape({
        ...sharedValidationObject,
    })
    return validation;
}

const ASSETGROUP_QUERY = gql`
query AssetGroup($assetGroupId: ID!){
    assetGroup(_id: $assetGroupId) {
        ag_title
    }
}
`;

const RA_BY_ID_QUERY = gql`
query ReferenceActivity($raId: ID!){
    referenceActivity(_id: $raId) {
        dh_id{
            _id
            dh_name
        }
        ag_id
        installation_id
        bar_id
        mah_id
        sece_id
        ps_id
        va_id
        ra_client_ref
        ra_title
        ra_desc
        ra_sample_size
        ra_sample_quantity
        ra_notes
        ra_attachment
    }
}`;

const CREATE_REFERENCE_ACTIVITY = gql`
    mutation AddReferenceActivity($ra: CreateReferenceActivity!) {
        createReferenceActivity(ra: $ra) {
            _id
            success
            status_code
            message
        }
    }
`;

const UPDATE_REFERENCE_ACTIVITY = gql`
    mutation UpdateReferenceActivity($id: String!, $userId: String, $ra: UpdateReferenceActivityInput!) {
        updateReferenceActivity(_id: $id, userId: $userId, ra: $ra) {
            dh_id{
                dh_name
            }
            ag_id
            installation_id
            bar_id
            mah_id
            sece_id
            ps_id
            va_id
            ra_client_ref
            ra_title
            ra_desc
            ra_sample_size 
            ra_sample_quantity
            ra_notes
            ra_originates_from
            success
            status_code
            message
        }
    }
`;

const RA_BY_REF_NO_COUNT_QUERY = gql`
query ReferenceActivityCount($refNo: String!, $dhId: String!){
    referenceActivityCount(refNo: $refNo, dhId: $dhId)
}`;

const AddEditReferenceActivity = (props) => {
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
    const [queryObject, setQueryObject] = useState({});
    const [assetGroupName, setAssetGroupName] = useState("N/A");

    const [raRefNoCount, setRaRefNoCount] = useState(undefined);
    const [enteredRaRefNo, setEnteredRaRefNo] = useState(undefined);
    const [drDnInstallation, setDrDnInstallation] = useState({});

    const authContext = useContext(AuthContext);
    const navContext = useContext(NavContext);
    const history = useHistory();

    const { formMode, id, assetGroupId, installationId, barrierId, seceId, mahId, raId, psId, vaId } = useParams();
    const { location: { state = "" }, match: { url = "" } } = props ? props : {};

    const [referenceActivity, { data: RaData }] = useLazyQuery(RA_BY_ID_QUERY);
    const [assetGroup, { data: agData }] = useLazyQuery(ASSETGROUP_QUERY);
    const [referenceActivityCount, { data: raCountData }] = useLazyQuery(RA_BY_REF_NO_COUNT_QUERY);
    const [createReferenceActivity, { data: createReferenceActivityData }] = useMutation(CREATE_REFERENCE_ACTIVITY);
    const [updateReferenceActivity, { data: updateReferenceActivityData }] = useMutation(UPDATE_REFERENCE_ACTIVITY);

    const [formInitValues, setFormInitValues] = useState({
        dh_id: id,
        ag_id: "",
        installation_id: "",
        bar_id: "",
        mah_id: "",
        sece_id: "",
        ps_id: "",
        va_id: "",
        ra_client_ref: "",
        ra_title: "",
        ra_desc: "",
        ra_sample_size: "",
        ra_sample_quantity: "",
        ra_notes: "",
        ra_attachment: "[]"
    });

    useEffect(() => {
        let queryObject = createQueryObject(url, 4);
        setQueryObject(queryObject);
        if (assetGroupId && assetGroupId !== "") {
            assetGroup({ variables: { assetGroupId: assetGroupId }, errorPolicy: 'all' })
        }
        if (formMode === "edit" && raId) {
            referenceActivity({ variables: { raId: raId }, errorPolicy: 'all' })
        }
    }, []);

    useEffect(() => {
        if (authContext && authContext.user) {
            let result = createUserTypeAndRoleObject(authContext);
            setUserTypeAndRoleObject(result);
        }
    }, [authContext]);

    useEffect(() => {
        const { installation } = navContext;
        const { id: prevId } = drDnInstallation;
    
        if (prevId && installation.id && prevId !== installation.id) {
          history.push('/');
        }
        setDrDnInstallation(installation);
    }, [navContext]);

    useEffect(() => {
        if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
            history.push('/access-permissions');
        }
    }, [userTypeAndRoleObject]);

    useEffect(() => {
        if (agData && agData.assetGroup) {
            let tempAgData = agData.assetGroup;
            let agTitle = tempAgData.ag_title ? tempAgData.ag_title : "N/A";
            setAssetGroupName(agTitle)
        }
    }, [agData]);

    useEffect(() => {
        if (RaData && RaData.referenceActivity) {
            let tempRaData = RaData.referenceActivity;
            let dhId = tempRaData.dh_id ? tempRaData.dh_id._id : "";

            let attachment = tempRaData.ra_attachment && tempRaData.ra_attachment !== "" ? tempRaData.ra_attachment : "[]";

            setFormInitValues({
                dh_id: dhId,
                ag_id: tempRaData.ag_id,
                installation_id: tempRaData.installation_id,
                bar_id: tempRaData.bar_id,
                mah_id: tempRaData.mah_id,
                sece_id: tempRaData.sece_id,
                ps_id: tempRaData.ps_id,
                va_id: tempRaData.va_id,
                ra_client_ref: tempRaData.ra_client_ref ? tempRaData.ra_client_ref : "",
                ra_title: tempRaData.ra_title,
                ra_desc: tempRaData.ra_desc,
                ra_sample_size: tempRaData.ra_sample_size,
                ra_sample_quantity: tempRaData.ra_sample_quantity,
                ra_notes: tempRaData.ra_notes,
                ra_attachment: attachment
            })
        }
    }, [RaData]);

    useEffect(() => {
        if (createReferenceActivityData) {
            const { success, message } = createReferenceActivityData.createReferenceActivity;

            if (!success) {
                alert(message);
            }

            if (success) {
                if (assetGroupId !== undefined && installationId === undefined && barrierId === undefined && seceId === undefined && mahId === undefined && psId === undefined && vaId === undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/reference-activities`);
                };
                if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId === undefined && mahId === undefined && psId === undefined && vaId === undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/reference-activities`);
                };
                if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId === undefined && mahId === undefined && psId === undefined && vaId === undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/reference-activities`);
                };
                if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId === undefined && mahId !== undefined && psId === undefined && vaId === undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/mah/${mahId}/reference-activities`);
                };
                if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId === undefined && mahId !== undefined && psId === undefined && vaId === undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/mah/${mahId}/reference-activities`);
                };
                if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && mahId === undefined && psId === undefined && vaId === undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/reference-activities`);
                };
                if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && mahId === undefined && psId === undefined && vaId === undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/reference-activities`);
                };
                if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && mahId === undefined && psId !== undefined && vaId === undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/${psId}/reference-activities`);
                };
                if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && mahId === undefined && psId !== undefined && vaId === undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/${psId}/reference-activities`);
                };
                if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && mahId === undefined && psId !== undefined && vaId !== undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/${psId}/verification-activity/${vaId}/reference-activities`);
                };
                if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && mahId === undefined && psId !== undefined && vaId !== undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/${psId}/verification-activity/${vaId}/reference-activities`);
                };
            }
        }
    }, [createReferenceActivityData, id, assetGroupId, installationId, barrierId, seceId, mahId]);

    useEffect(() => {
        if (updateReferenceActivityData) {
            const { success, message } = updateReferenceActivityData.updateReferenceActivity;
            if (!success) {
                alert(message);
            }
            if (success) {
                if (assetGroupId !== undefined && installationId === undefined && barrierId === undefined && seceId === undefined && mahId === undefined && psId === undefined && vaId === undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/reference-activities`);
                };
                if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId === undefined && mahId === undefined && psId === undefined && vaId === undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/reference-activities`);
                };
                if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId === undefined && mahId === undefined && psId === undefined && vaId === undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/reference-activities`);
                };
                if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId === undefined && mahId !== undefined && psId === undefined && vaId === undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/mah/${mahId}/reference-activities`);
                };
                if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId === undefined && mahId !== undefined && psId === undefined && vaId === undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/mah/${mahId}/reference-activities`);
                };
                if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && mahId === undefined && psId === undefined && vaId === undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/reference-activities`);
                };
                if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && mahId === undefined && psId === undefined && vaId === undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/reference-activities`);
                };
                if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && mahId === undefined && psId !== undefined && vaId === undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/${psId}/reference-activities`);
                };
                if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && mahId === undefined && psId !== undefined && vaId === undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/${psId}/reference-activities`);
                };
                if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && mahId === undefined && psId !== undefined && vaId !== undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/${psId}/verification-activity/${vaId}/reference-activities`);
                };
                if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && mahId === undefined && psId !== undefined && vaId !== undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/${psId}/verification-activity/${vaId}/reference-activities`);
                };
            }
        }
    }, [updateReferenceActivityData, assetGroupId]);

    useEffect(() => {
        if (raCountData) {
            if (formMode === "edit" && enteredRaRefNo === formInitValues.ra_client_ref) {
                setRaRefNoCount(0);
            } else {
                setRaRefNoCount(raCountData.referenceActivityCount);
            }
        }
    }, [raCountData]);

    const handleRaRefNoChange = (event, dhId) => {
        let refNo = event.target.value;
        setEnteredRaRefNo(refNo);
        referenceActivityCount({ variables: { refNo: refNo, dhId: dhId }, errorPolicy: 'all' });
    }

    let tempRaAttachFileList = [];
    const handleRaAttachFilesDescription = (item) => {
        let index = tempRaAttachFileList.findIndex(f => f.name === item.name);
        if (index >= 0) {
            tempRaAttachFileList[index] = item;
        } else {
            tempRaAttachFileList.push(item);
        }
    };

    return (
        <div className="w-full px-8 pb-8">

            <div className="pb-3 border-b-2 border-gray-200">
                {formMode === "add" &&
                    <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{formMode} {getSectionName(url, 4).replace("-", " ")} Reference Activity</h1>
                }
                {formMode === "edit" &&
                    <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{formMode} {getSectionName(url, 5).replace("-", " ")} Reference Activity</h1>
                }
            </div>

            <Formik
                enableReinitialize
                initialValues={omit(formInitValues, '__typename')}
                validationSchema={validation(raRefNoCount)}
                onSubmit={(values, actions) => {
                    
                    const {
                        dh_id,
                        ag_id,
                        installation_id,
                        bar_id,
                        mah_id,
                        sece_id,
                        ps_id,
                        va_id,
                        ...newValues
                      } = values;


                    let tempValues = {
                        ...(dh_id && dh_id !== '' && { dh_id: dh_id }),
                        ...(ag_id && ag_id !== '' && { ag_id: ag_id }),
                        ...(installation_id && installation_id !== '' && { installation_id: installation_id }),
                        ...(bar_id && bar_id !== '' && { bar_id: bar_id }),
                        ...(mah_id && mah_id !== '' && { mah_id: mah_id }),
                        ...(sece_id && sece_id !== '' && { sece_id: sece_id }),
                        ...(ps_id && ps_id !== '' && { ps_id: ps_id }),
                        ...(va_id && va_id !== '' && { va_id: va_id }),
                        ...newValues
                    };

                    const { _id: userId = "" } = authContext.user ? authContext.user : {};

                    tempValues.ra_attachment = updateAttachmentData(tempValues.ra_attachment, tempRaAttachFileList);

                    if (formMode === 'add') {
                        tempValues.ra_added_id = userId;
                        tempValues.ra_base_path = removeLastNthParamFrmUrl(url, 1);

                        const newTempValues = { ...tempValues, ...queryObject }
                        createReferenceActivity({ variables: { ra: newTempValues } })
                    }

                    if (formMode === 'edit') {
                        updateReferenceActivity({ variables: { id: raId, userId: userId, ra: tempValues } })
                    }

                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit}>

                        <div className="my-4">
                            {displayBackLink(state, id, assetGroupId, installationId, barrierId, seceId, mahId, psId, vaId)}
                        </div>

                        {formMode === "add" &&
                            <p className="mb-3 text-red-900 font-sans font-semibold">You are adding a reference activity to {getSectionName(url, 4).replace("-", " ")}</p>
                        }

                        {formMode === "edit" &&
                            <p className="mb-3 text-red-900 font-sans font-semibold">You are editing a reference activity for {getSectionName(url, 5).replace("-", " ")}</p>
                        }

                        <div className="my-4">
                            <label htmlFor="ag_name" className="block mb-1 text-blue-900 font-semibold">Asset Group Name</label>
                            <input
                                type="text"
                                readOnly
                                disabled
                                className="form-control block w-full md:w-1/2 bg-gray-100"
                                name="ag_name"
                                id="ag_name"
                                value={assetGroupName}
                            />
                        </div>
                        <div>
                            <label htmlFor="ra_client_ref" className="block mb-1 text-blue-900 font-semibold">Client Ref*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="ra_client_ref"
                                id="ra_client_ref"
                                placeholder="Client Ref"
                                onChange={(event) => { handleChange(event); handleRaRefNoChange(event, id); }}
                                onBlur={handleBlur}
                                value={values.ra_client_ref}
                            />
                            <FormError touched={touched.ra_client_ref} message={errors.ra_client_ref} />
                        </div>
                        <div>
                            <label htmlFor="ra_title" className="block mb-1 text-blue-900 font-semibold">Title*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="ra_title"
                                id="ra_title"
                                placeholder="Title"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.ra_title}
                            />
                            <FormError touched={touched.ra_title} message={errors.ra_title} />
                        </div>

                        <div>
                            <label htmlFor="ra_desc" className="block mb-1 text-blue-900 font-semibold">Description*</label>
                            <textarea
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="ra_desc"
                                id="ra_desc"
                                placeholder="Description*"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.ra_desc}
                                rows="7"
                            />
                            <FormError touched={touched.ra_desc} message={errors.ra_desc} />
                        </div>


                        <div>
                            <label htmlFor="ra_sample_size" className="block mb-1 text-blue-900 font-semibold">Sample Size*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="ra_sample_size"
                                id="ra_sample_size"
                                placeholder="Sample Size"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.ra_sample_size}
                            />
                            <FormError touched={touched.ra_sample_size} message={errors.ra_sample_size} />
                        </div>

                        <div>
                            <label htmlFor="ra_sample_quantity" className="block mb-1 text-blue-900 font-semibold">Sample Quantity*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="ra_sample_quantity"
                                id="ra_sample_quantity"
                                placeholder="Sample Quantity"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.ra_sample_quantity}
                            />
                            <FormError touched={touched.ra_sample_quantity} message={errors.ra_sample_quantity} />
                        </div>

                        <div>
                            <label htmlFor="ra_notes" className="block mb-1 text-blue-900 font-semibold">Notes</label>
                            <textarea
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="ra_notes"
                                id="ra_notes"
                                placeholder="Notes"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.ra_notes}
                                rows="7"
                            />
                            <FormError touched={touched.ra_notes} message={errors.ra_notes} />
                        </div>

                        <div>
                            <label htmlFor="ra_attachment" className="block mb-1 text-blue-900 font-semibold">Attachments</label>

                            {authContext.user && authContext.user.user_home_dir &&
                                <FileUpload
                                    onAttach={(files) => setFieldValue('ra_attachment', JSON.stringify(files))}
                                    id='fileManager'
                                    buttonText='Attach Files'
                                    apiURL={REACT_APP_FILE_MANAGER_API}
                                    toolbarText='Attach'
                                    homeDir={`/${authContext.user.user_home_dir}/`}
                                    showDescription={true}
                                    handleFileDescription={handleRaAttachFilesDescription}
                                    tempFileList={tempRaAttachFileList}
                                    {...(formMode === 'edit' && { attachmentList: parseJSONSafely(values.ra_attachment) ? parseJSONSafely(values.ra_attachment) : [] })}
                                />
                            }
                            <FormError touched={touched.ra_attachment} message={errors.ra_attachment} />

                        </div>

                        <button type="submit" className="mt-3 button-red capitalize">{formMode} Reference Activity</button>

                    </form>
                )}
            </Formik>

        </div>
    )
}

function displayBackLink(state, id, assetGroupId, installationId, barrierId, seceId, mahId, psId, vaId) {
    if (assetGroupId !== undefined && installationId === undefined && barrierId === undefined && seceId === undefined && mahId === undefined && psId === undefined && vaId === undefined) {
        return (
            <Link to={{
                pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/reference-activities` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
                state: state && state.archivedStatus
            }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
        )
    };
    if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId === undefined && mahId === undefined && psId === undefined && vaId === undefined) {
        return (
            <Link to={{
                pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/reference-activities` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
                state: state && state.archivedStatus
            }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
        )
    };
    if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId === undefined && mahId === undefined && psId === undefined && vaId === undefined) {
        return (
            <Link to={{
                pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/reference-activities` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
                state: state && state.archivedStatus
            }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
        )
    };
    if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId === undefined && mahId !== undefined && psId === undefined && vaId === undefined) {
        return (
            <Link to={{
                pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/mah/${mahId}/reference-activities` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
                state: state && state.archivedStatus
            }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
        )
    };
    if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId === undefined && mahId !== undefined && psId === undefined && vaId === undefined) {
        return (
            <Link to={{
                pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/mah/${mahId}/reference-activities` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
                state: state && state.archivedStatus
            }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
        )
    };

    if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && mahId === undefined && psId === undefined && vaId === undefined) {
        return (
            <Link to={{
                pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/reference-activities` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
                state: state && state.archivedStatus
            }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
        )
    };
    if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && mahId === undefined && psId === undefined && vaId === undefined) {
        return (
            <Link to={{
                pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/reference-activities` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
                state: state && state.archivedStatus
            }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
        )
    };
    if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && mahId === undefined && psId !== undefined && vaId === undefined) {
        return (
            <Link to={{
                pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/${psId}/reference-activities` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
                state: state && state.archivedStatus
            }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
        )
    };
    if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && mahId === undefined && psId !== undefined && vaId === undefined) {
        return (
            <Link to={{
                pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/${psId}/reference-activities` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
                state: state && state.archivedStatus
            }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
        )
    };
    if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && mahId === undefined && psId !== undefined && vaId !== undefined) {
        return (
            <Link to={{
                pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/${psId}/verification-activity/${vaId}/reference-activities` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
                state: state && state.archivedStatus
            }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
        )
    };
    if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && mahId === undefined && psId !== undefined && vaId !== undefined) {
        return (
            <Link to={{
                pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/${psId}/verification-activity/${vaId}/reference-activities` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
                state: state && state.archivedStatus
            }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
        )
    };

}

export default AddEditReferenceActivity;


