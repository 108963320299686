/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import FormError from "../global/FormError";
import {Link, useHistory} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {gql, useMutation} from '@apollo/client';
import {AuthContext} from "../../context/authContext";
import FileUpload from '../global/FileUpload';
import {updateAttachmentData, createUserTypeAndRoleObject} from "../../utils";
import PageSpinner from '../global/pageSpinner';

const {REACT_APP_FILE_MANAGER_API} = process.env;

const Validation = Yup.object().shape({
  dh_code: Yup.string().matches(/^[A-Z]{3}$/, 'Must be exactly 3 Uppercase Characters').label("dh_code").required("Duty Holder Code is required"),
  dh_name: Yup.string().label("dh_name").required("Duty Holder Name is required"),
  dh_description: Yup.string().label("dh_description").required("Description is required"),
})

const CREATE_DUTY_HOLDER = gql`
    mutation CreateDutyHolder($dutyHolder: DutyHolderInput!) {
        createDutyHolder(dutyHolder: $dutyHolder) {
            _id
            dh_code
            dh_name
            dh_location
            dh_description
            dh_child_id
            dh_added_date
            dh_added_id {
                _id
            }
            dh_attachments
            dh_archived
            dh_archived_id
            dh_archived_date
            success
            status_code
            message
        }
    }
`;

const AddDutyHolder = () => {
  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const history = useHistory();

  const [showHelp, setShowHelp] = useState(false);
  
  const [createDutyHolder, {data}] = useMutation(CREATE_DUTY_HOLDER);

  useEffect(() => {
    if (authContext && authContext.user) {
      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
      history.push('/access-permissions');
    }
  }, [userTypeAndRoleObject]);

  useEffect(() => {
    if (data && data.createDutyHolder) {
      const {success, message} = data.createDutyHolder;
      if (!success) {
          alert(message);
      }
      if (success) {
        history.push('/dutyholders');
      }   
    }
  }, [data]);

  let tempDhAttachFileList = [];
  const handleDhAttachFilesDescription = (item) => {
    let index = tempDhAttachFileList.findIndex(f => f.name === item.name);
    if (index >= 0) {
      tempDhAttachFileList[index] = item;
    } else {
      tempDhAttachFileList.push(item);
    }
  };

  if (!(authContext && authContext.user)) {
    return (
      <PageSpinner/>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-3 border-b-2 border-gray-200">
        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Add Duty Holder <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
        <div>
            <p>Enter the details into the required fields.</p>
            <p>Once complete, click 'Add Duty Holder'.</p>
        </div>
        }
      </div>

      <Formik
        initialValues={{dh_code: "", dh_name: "", dh_location: [], dh_description: "", dh_attachments:""}}
        validationSchema={Validation}
        onSubmit={(values, actions) => {
          const payload = {...values};

          let dhAttachments = updateAttachmentData(values.dh_attachments, tempDhAttachFileList);
          
          payload.dh_attachments = dhAttachments;
          payload.dh_added_id = authContext.user._id;

          createDutyHolder({variables: {dutyHolder: payload}})
        }}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            handleSubmit,
            isSubmitting
          }) => (
          <form onSubmit={handleSubmit}>

            <div className="my-4">
              <Link to="/dutyholders" className="button-red"><FontAwesomeIcon icon={['fas', 'angle-left']}
                                                                              className="text-white"/> Back</Link>
            </div>

            <div>
              <label htmlFor="dh_code" className="block mb-1 text-blue-900 font-semibold">Duty Holder Code (3
                Letters)*</label>
              <input
                type="text"
                className="form-control block w-full md:w-1/2"
                name="dh_code"
                id="dh_code"
                placeholder="Duty Holder Code"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.dh_code}
              />
              <FormError touched={touched.dh_code} message={errors.dh_code}/>
            </div>

            <div>
              <label htmlFor="dh_name" className="block mb-1 text-blue-900 font-semibold">Duty Holder Name*</label>
              <input
                type="text"
                className="form-control block w-full md:w-1/2"
                name="dh_name"
                id="dh_name"
                placeholder="Duty Holder Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.dh_name}
              />
              <FormError touched={touched.dh_name} message={errors.dh_name}/>
            </div>

            <div>
              <label htmlFor="dh_description" className="block mb-1 text-blue-900 font-semibold">Description*</label>
              <textarea
                type="text"
                className="form-control block w-full md:w-1/2"
                name="dh_description"
                id="dh_description"
                placeholder="Description"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.dh_description}
              />
              <FormError touched={touched.dh_description} message={errors.dh_description}/>
            </div>

            <div>
              <label htmlFor="dh_attachments" className="block mb-1 text-blue-900 font-semibold">Attachments</label>

              {authContext.user && authContext.user.user_home_dir &&
                <FileUpload
                  onAttach={(files) => setFieldValue('dh_attachments', JSON.stringify(files))}
                  id='fileManager'
                  buttonText='Attach Files'
                  apiURL={REACT_APP_FILE_MANAGER_API}
                  toolbarText='Attach'
                  homeDir={`/${authContext.user.user_home_dir}/`}
                  showDescription={true}
                  handleFileDescription={handleDhAttachFilesDescription}
                  tempFileList={tempDhAttachFileList}
                />
              }
              <FormError touched={touched.dh_attachments} message={errors.dh_attachments} />

            </div>

            <button type="submit" className="mt-3 button-red capitalize">Add Duty Holder</button>

          </form>
        )}
      </Formik>

    </div>
  )

}

export default AddDutyHolder;
